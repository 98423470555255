@import "../scss/variables.scss";
@import "../scss/typography.scss";
@import "./WhyUsCard.scss";

#whyUs {
  display: flex;
  align-items: center;
  flex-direction: column;
  h1 {
    text-align: center;
  }
  .whyUsCardsContainer > * + * {
    margin: 0 0 0 30px;
    @include breakpoint(mobile) {
      // margin-left: 0;
      margin: 20px 0 0 0;
    }
  }
  .whyUsCardsContainer {
    margin: 72px 0 0 0;
    display: flex;
    @include breakpoint(mobile) {
      flex-direction: column;
    }
  }
}
