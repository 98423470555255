@import "./variables.scss";

.shipment__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  margin-bottom: 50px;
  @include breakpoint(mobile) {
    padding: 0px $mobileMargin;
    background-color: #eeeeee;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    z-index: 110;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    transition: all 0.5s ease;

    &.active {
      background-color: #eeeeee;
    }
    #registerCorrect__wrapper ~ & {
      background-color: transparent;
      &.active {
        background-color: #eee;
      }
    }
  }
  .menu-options {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    text-align: center;
    & > * + * {
      margin: 0 0 0 56px;
    }
    @include breakpoint(mobile) {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 75px;
      left: -100%;
      opacity: 0;
      transition: all 0.5s ease;
      flex-direction: column;
      list-style-type: none;
      background-color: #eeeeee;
      & > * + * {
        margin: 10px 0 0 0;
      }

      &.active {
        background-color: #eeeeee;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 10;
        align-content: flex-start;
        justify-content: flex-start;
        height: 93vh;
      }
      .option {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 28px 0px;
        width: 100%;
        border-bottom: 2px solid rgba(0, 0, 0, 0.05);
        a {
          width: 100%;
          text-align: left;
        }
      }
    }
  }
  .buttons {
    .menu {
      a {
        margin-right: 20px;
        &:active {
          text-decoration: none;
        }
        &:focus {
          font-weight: bold;
          text-decoration: none;
        }
      }
    }
    @include breakpoint(mobile) {
      display: none;
    }
  }
  .buttons.mobile-option {
    display: none;
    @include breakpoint(mobile) {
      align-self: center;
      display: flex;
      flex-direction: column;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      & > * + * {
        margin: 20px 0 0 0;
      }
      .btn {
        width: 100%;
        &.logout {
          border: 1px solid $btnColorPrimary;
        }
      }
    }
  }

  .burger-menu {
    display: none;
    @include breakpoint(mobile) {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 30px;
      height: 30px;
      cursor: pointer;
      transition: all 0.5s ease-in-out;
      &__icon {
        width: 27px;
        height: 4px;
        background-color: $btnColorPrimary;
        border-radius: 5px;
        transition: all 0.5s ease-in-out;
      }
      &__icon::before,
      &__icon::after {
        content: "";
        position: absolute;
        width: 27px;
        height: 4px;
        background-color: $btnColorPrimary;
        border-radius: 5px;
        transition: all 0.5s ease-in-out;
      }
      &__icon::before {
        transform: translateY(-10px);
      }
      &__icon::after {
        transform: translateY(10px);
      }
      &.open .burger-menu__icon {
        transform: translateX(30px);
        background-color: transparent;
      }
      &.open .burger-menu__icon::before {
        transform: translate(-30px, 0px) rotate(45deg);
      }
      &.open .burger-menu__icon::after {
        transform: translate(-30px, 0px) rotate(-45deg);
      }
    }
  }
  li {
    color: #212b36;
  }
}
