@import "./typography.scss";
@import "./mixins.scss";

$black: #212b36;
$mint: #56cbb9;
$failure: #d80027;
// Buttons
$btnColor: #ededed;
$btnColorPrimary: #7974ff;
$btnColorPrimaryHover: #5751f3;
$orange: #ff9371;
$btnBorderRadius: 32px;
$btnWidth: 144px;
$btnHeight: 47px;

// Menu
$menuItemMarginRight: 56px;

// Margins
$sideMargin: 70px;
$topMargin: 106px;
$mobileMargin: 20px;

// Checkbox
$checkboxSize: 21px;

//WhyUs
$headerMargin: 30px 20px 0;
$textMargin: 0px 20px 40px;
