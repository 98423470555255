@import "./variables.scss";

.accountModal {
  position: relative;
  display: block;
  transition: all 0.5s ease;
  @include breakpoint(mobile) {
    display: none;
  }
  a:after {
    padding-left: 10px;
    content: url(../img-svg/VectorDown.svg);
  }
  a:hover:after {
    color: $btnColorPrimary;
    content: url(../img-svg/VectorDownPurple.svg);
  }
  .modal {
    display: none;
    flex-direction: column;
    justify-content: center;
    padding: 20px 20px 27px;
    width: 225px;
    position: absolute;
    top: 0px;
    left: -13px;
    transition: all 0.5s ease;
    background-color: $btnColorPrimary;
    border-radius: 24px;
    z-index: 99;
    opacity: 0;
    &:hover {
      background-color: $btnColorPrimaryHover;
    }
    .closeModal {
      align-self: flex-end;
      margin-bottom: 32px;
      span {
        letter-spacing: 0.5px;
        color: #ffffff;
        margin-right: 20px;
      }
    }
    .modalButtons {
      white-space: nowrap;
      display: flex;
      flex-direction: column;
      justify-content: center;
      & > * + * {
        margin: 9px 0 0 0;
      }
      .btn {
        width: 100%;
        position: relative;
        padding: 0;
      }
    }
    &.active {
      display: flex;
      z-index: 100;
      left: -155px;
      top: -20px;
      opacity: 1;
      transition: all 0.5s ease;
    }
  }
  .plusButton {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    z-index: 99;
    &__icon {
      width: 27px;
      height: 4px;
      background-color: $btnColorPrimary;
      border-radius: 5px;
      transition: all 0.5s ease-in-out;
    }
    &__icon::before {
      content: "";
      position: absolute;
      width: 27px;
      height: 4px;
      transform: rotate(90deg);
      background-color: $btnColorPrimary;
      border-radius: 5px;
      transition: all 0.5s ease-in-out;
    }
    &.open .plusButton__icon {
      transform: rotate(45deg);
    }
    &.open .plusButton__icon,
    &.open .plusButton__icon::before {
      background-color: #fff;
    }
  }
}
