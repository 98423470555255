@import "./variables.scss";

#contact {
  display: flex;
  margin-top: 30px;
  position: relative;
  @include breakpoint(mobile) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .contact__form {
    width: 50vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include breakpoint(mobile) {
      width: 100%;
    }
    h1 {
      @include breakpoint(mobile) {
        align-self: flex-start;
      }
    }
    form {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 3vh;
      flex-direction: column;
      flex-wrap: nowrap;
      width: 100%;
      @include breakpoint(mobile) {
        align-items: flex-start;
      }
      .formItem {
        margin-bottom: 3vh;
        display: flex;
        justify-content: center;
        @include breakpoint(mobile) {
          width: 100%;
        }
      }
      button {
        margin-right: 0;
        width: 190px;
      }
    }
    .send-failure {
      position: absolute;
      bottom: -20px;
      & > p {
        color: $failure;
        text-align: center;
      }
    }
  }
  .contact__msgSend {
    width: 50vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include breakpoint(mobile) {
      width: 100%;
      align-items: flex-start;
    }
    img {
      margin-bottom: 48px;
      @include breakpoint(mobile) {
        order: 3;
        align-self: center;
      }
    }
    h1 {
      margin-bottom: 14px;
      @include breakpoint(mobile) {
        text-align: left;
        order: 1;
      }
    }
    p {
      margin-bottom: 51px;
      @include breakpoint(mobile) {
        order: 2;
      }
    }
    button {
      margin-right: 0;
      width: 190px;
      @include breakpoint(mobile) {
        order: 4;
        align-self: center;
        margin-bottom: 20px;
      }
    }
  }
  .contact__people {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include breakpoint(mobile) {
      margin-top: 0;
    }
    h1 {
      display: none;
    }
    @include breakpoint(mobile) {
      h1 {
        display: unset;
        margin: 80px 0 40px;
      }
    }
  }
}
