.MuiFormControl-root.customSelect {
  margin-right: 10px;
  min-width: 95px;
  height: 50px;
  background-color: #fff;
  border: 1px solid #e9edf4;
  border-radius: 6px;
  flex-direction: row;
  justify-content: center;
  & .MuiInput-formControl {
    margin-top: 0;
    width: 100%;
    cursor: pointer;
  }
  & .MuiInput-underline:before {
    display: none;
  }
  & .MuiInputBase-input {
    padding: 0px 16px 0 9px;
    height: 100%;
  }
  & .MuiInput-underline:after {
    border-bottom: 1px solid #5751f3;
  }
  & .MuiSelect-select:focus {
    background-color: transparent;
  }
  & .MuiSelect-icon {
    right: 2px;
  }
  & .MuiSelect-selectMenu {
    display: flex;
    align-items: center;
    & span {
      margin-left: 7px;
    }
  }
  &:hover {
    background-color: #f9f9ff;
  }
}

.MuiPopover-root {
  .MuiPaper-root {
    .MuiList-root {
      &.MuiList-padding {
        padding: 0;
      }
      &.MuiMenu-list {
        .MuiButtonBase-root.MuiListItem-root {
          &.MuiListItem-gutters {
            padding: 12px 0px 12px 9px;
          }
          & span {
            margin-left: 7px;
          }
          &.MuiListItem-button:hover {
            background-color: rgba(121, 116, 255, 0.08);
          }
          &.Mui-selected {
            background-color: rgba(121, 116, 255, 0.15);
          }
        }
      }
    }
  }
}
