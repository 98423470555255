form {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  justify-content: space-between;

  select {
    display: none;
  }

  small {
    font-size: 14px;
    line-height: 22px;
    opacity: 0.5;
    padding-left: 5px;
  }

  .inputGroup {
    display: flex;
    justify-content: space-between;

    .separator {
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.5;
      font-size: 0.875rem;
      width: 40%;

      @include breakpoint(mobile) {
        width: unset;
      }
    }
  }

  .formItem {
    margin-bottom: 40px;
    width: 49%;

    .btn-container {
      display: flex;

      & > * + * {
        margin: 0 0 0 16px;
      }

      @include breakpoint(mobile) {
        align-items: center;
        flex-direction: column;
        margin-bottom: 40px;

        & > * + * {
          margin: 0.5rem 0 0 0;
        }
      }

      .btn {
        width: 250px;

        @include breakpoint(mobile) {
          width: 100%;
        }
      }
    }

    label {
      display: flex;
      align-items: center;
      font-size: 1.5rem;
      margin-bottom: 19px;

      &.container {
        font-size: 0.875rem;
        border: 2px solid transparent;
        width: fit-content;

        a,
        span {
          font-size: 0.875rem;
          padding-left: 1ch;
          padding-right: 1ch;
          color: $btnColorPrimary;
        }
      }
    }

    &.formItemAlert {
      flex-basis: 100%;
    }
  }

  .formItemAlert {
    @include breakpoint(mobile) {
      margin-bottom: 20px;
    }
  }

  .parcel-select__container {
    display: flex;
    width: 100%;

    & > * + * {
      margin: 0 0 32px 32px;
    }

    @include breakpoint(mobile) {
      flex-direction: column;
      margin: 0 0 24px 0;

      & > * + * {
        margin: 24px 0 0 0;
      }
    }

    .formItem {
      @include breakpoint(mobile) {
        margin-bottom: 0;
        width: 100%;
      }
    }

    .select {
      display: flex;

      @include breakpoint(mobile) {
        width: 100%;
      }
    }
  }

  .parcel-properties__container {
    display: flex;
    width: 100%;
    flex-direction: column;

    & > * + * {
      margin: 0 0 0 15px;
    }

    @include breakpoint(mobile) {
      flex-direction: column;
      margin: 15px 0;

      & > * + * {
        margin: 15px 0 0 0px;
      }

      .formItem {
        margin: 0;
      }
    }

    .size {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .separator {
        @include breakpoint(mobile) {
          padding: 0 5px;
        }
      }

      @include breakpoint(mobile) {
        width: 100%;

        .formItem {
          width: 100%;
        }
      }
    }

    .weight {
      width: 25%;
      margin-left: 0;

      @include breakpoint(mobile) {
        width: 100%;
        margin-bottom: 30px;
      }

      @include breakpoint(smallMobile) {
        margin-bottom: 30px;
        margin-left: 0;
      }
    }

    .formItem {
      width: 100%;
    }
  }

  .insurance {
    display: flex;
    align-items: center;

    p,
    a {
      font-size: 14px;
      line-height: 22px;
      color: #212b36;
    }

    a {
      color: $btnColorPrimary;
    }
  }
}

.landingBackground {
  position: absolute;
  width: 100vw;
  height: 100%;
  left: -1.5 * $btnWidth;
  top: 47px;
  border-radius: 32px;
  background: linear-gradient(180deg, rgba(238, 238, 238, 0) 0%, #eeeeee 100%);
  z-index: -1;

  @include breakpoint(mobile) {
    display: none;
  }
}

.hero-background {
  position: relative;
}

.hero {
  display: flex;

  .btn-container {
    justify-content: flex-start;
  }

  @include breakpoint(mobile) {
    margin-top: 0;
  }

  main {
    width: 45vw;
    margin-top: 30px;

    @include breakpoint(mobile) {
      margin-top: 0;
    }

    h1 {
      padding-right: 50px;
    }

    @include breakpoint(mobile) {
      width: 100vw;
    }
  }
}
