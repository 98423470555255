* {
  margin: 0;
  text-decoration: none;
  border: none;
  padding: 0;
  outline: none;
  font-family: $fontFamily;
  font-size: $fontSize16;
  box-sizing: border-box;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
