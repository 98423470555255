@import "./variables.scss";

.contact-card {
  display: flex;
  margin-bottom: 4vh;
  .contact-avatar {
    @include breakpoint(smallMobile) {
      display: none;
    }
    img {
      width: 100px;
      @include breakpoint(mobile) {
        width: 70px;
      }
    }
  }
  .contact-data {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    margin-left: 20px;

    h4 {
      font-size: 25px;
      line-height: 29px;
      color: #212b36;
    }
    p {
      font-size: 16px;
      line-height: 19px;
      color: #212b36;
      opacity: 0.6;
    }
    a {
      margin: 17px 0 0 0;
      font-size: 16px;
      line-height: 19px;
      color: $btnColorPrimary;
      padding-bottom: 17px;
      position: relative;
      transition: 0.4s;
      @include breakpoint(mobile) {
        margin-left: 0px;
      }
    }
    a + a {
      margin-top: 0px;
    }
    a:hover {
      color: $btnColorPrimaryHover;
    }
  }
}
