@import "./variables.scss";

.employee-card {
  display: flex;
  img {
    width: 74px;
    @include breakpoint(smallMobile) {
      display: none;
    }
  }
  .employee-data {
    display: flex;
    flex-direction: column;
    margin-left: 45px;
    @include breakpoint(mobile) {
      margin-left: 20px;
    }

    h4 {
      padding-bottom: 2px;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 25px;
      line-height: 29px;
      color: #d8d8d8;
    }
    p {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #d8d8d8;
      opacity: 0.6;
      padding-bottom: 15px;
    }
    a {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: $btnColorPrimary;
      padding-bottom: 17px;
    }
  }
}
