$mobile: 360px;
#dashboard {
  .hero {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .buttons {
      .btn,
      .primary {
        padding: 12px 21px;
      }
      .btn::after {
        content: url(../img-svg/PlusSymbolDark.svg);
        margin-left: 10px;
      }
      .btn:last-child::after {
        content: url(../img-svg/PlusSymbol.svg);
      }
      @include breakpoint(mobile) {
        display: none;
      }
    }
    .mobile {
      display: none;
      @include breakpoint(mobile) {
        display: block;
      }
    }
  }

  & + .sub-nav__wrapper {
    margin-top: 0;
  }
}

.dashboardHeading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    margin-top: 0;
  }
}

.dashboardTable {
  width: 100%;
  margin-top: 63px;
  h2 {
    font-weight: 700;
    font-size: 1.56rem;
  }
}

// .horizontal-scroll-wrapper {
//   // @include breakpoint(mobile) {
//   width: 100px;
//   height: 100%;
//   overflow-y: auto;
//   overflow-x: hidden;
//   transform: rotate(-90deg);
//   transform-origin: right top;
//   transform: rotate(-90deg) translateY(-100px);
//   & > .chip {
//     min-width: 100%;
//     height: 147px;
//     transform: rotate(90deg);
//     transform-origin: right top;
//   }
// }
