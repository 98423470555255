@import "./variables.scss";

#user-data__form {
  h1 {
    margin-bottom: 40px;
  }
  input {
    @include breakpoint(mobile) {
      max-width: 100%;
    }
  }
  .form {
    margin: 0;
    display: flex;
    flex-wrap: wrap-reverse;
    .main-form {
      min-width: 300px;
      width: 40%;
      margin-right: 30px;
      @include breakpoint(mobile) {
        width: 100%;
        margin-right: 0;
      }
      form {
        margin: 0;
        @include breakpoint(mobile) {
          justify-content: center;
        }
        input {
          max-width: 100%;
        }
      }
    }
  }
  .btn-container {
    width: 30vw;
    @include breakpoint(mobile) {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .formItem {
      width: 30vw;
      display: flex;
      justify-content: space-between;
      & > * + * {
        margin: 0 0 0 20px;
      }
    }
    .btn {
      min-width: 50%;
      max-width: 100%;
      white-space: nowrap;
    }
    &.mobile {
      display: none;
      @include breakpoint(mobile) {
        display: flex;
        justify-content: center;
        .formItem {
          display: flex;
          flex-direction: column;
          & > * + * {
            margin: 20px 0 0 0;
          }
        }
      }
    }
  }

  .side-btn {
    margin-bottom: 40px;
    @include breakpoint(mobile) {
      display: none;
    }
  }

  .save-failure,
  .save-correct {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    text-align: center;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 1000;
    @include breakpoint(mobile) {
      top: 80px;
      border-radius: 4px;
    }
    p {
      @include smallTag;
      color: white;
      padding: 5px $mobileMargin;
    }
  }
  .save-failure {
    background-color: $failure;
  }
  .save-correct {
    background-color: #56cbb9;
  }
}
