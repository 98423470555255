@import "./variables.scss";

#price-list {
  .content {
    display: flex;
    padding-top: 45px;
    justify-content: space-between;
    margin-bottom: 100px;
    @include breakpoint(mobile) {
      padding-top: 0px;
      flex-direction: column;
      width: 100%;
    }
    select {
      display: none;
    }
    main {
      width: 45vw;
      @include breakpoint(mobile) {
        width: 100%;
      }
      .questionMark {
        display: flex;
        align-items: center;
        margin: 0 0 20px;
        cursor: pointer;
        .qestionIcon {
          fill: $btnColorPrimary;
        }
        & > * + * {
          margin: 0 0 0 10px;
          color: $btnColorPrimary;
          text-decoration: underline;
        }
      }
    }
    .order__summary {
      margin-top: 70px;
      position: absolute;
      right: $sideMargin;
      width: 500px;
      height: 500px;
      background-image: url(../img-svg/priceListShape.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;

      @include breakpoint(mobile) {
        background-image: none;
        position: unset;
        top: 0;
        right: 0;
        transform: translate(unset);
        width: 100%;
        height: auto;
        margin: 0;
        background-color: white;
      }
      div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include breakpoint(mobile) {
          width: 100%;
          height: 100%;
          transform: translate(0, 0);
          position: unset;
          padding: 12px 0;
          border-top: 2px solid rgba($color: #000000, $alpha: 0.08);
          border-bottom: 2px solid rgba($color: #000000, $alpha: 0.08);
        }
      }
      p {
        font-family: Roboto;
        font-style: italic;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: #000000;
        margin-bottom: 15px;
        span {
          opacity: 0.5;
        }
        &.upper {
          text-transform: uppercase;
          span {
            opacity: 0.3;
          }
        }
      }
      h1 {
        color: #56cbb9;
        margin-bottom: 20px;
      }
      &.hidden-mobile {
        @include breakpoint(mobile) {
          display: none;
        }
      }
      &.hidden-deskop {
        display: none;
        @include breakpoint(mobile) {
          display: unset;
        }
      }
      .informationHidden {
        width: 484px;
        height: 436px;
        border-radius: 10px;
        opacity: 0;
        transition: all 0.3s ease;
        cursor: default;
        &.informationActive {
          background-color: $btnColorPrimary;
          opacity: 1;
          z-index: 1000;
        }
        .plusButton {
          position: relative;
          cursor: pointer;
          transition: all 0.5s ease-in-out;
          z-index: 10000;
          &__icon {
            cursor: pointer;
            top: 27px;
            right: 20px;
            left: unset;
            width: 27px;
            height: 4px;
            background-color: #fff;
            border-radius: 5px;
            transition: all 0.5s ease-in-out;
            transform: rotate(45deg);
          }
          &__icon::before {
            content: "";
            position: absolute;
            width: 27px;
            height: 4px;
            transform: rotate(90deg);
            background-color: #fff;
            border-radius: 5px;
            transition: all 0.5s ease-in-out;
          }
        }
        .parcelInfo {
          width: 65%;
          padding: 35px;
          top: 0;
          left: 0;
          transform: unset;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          font-style: normal;
          p {
            font-size: 24px;
            line-height: 28px;
            color: #fff;
            margin-bottom: 40px;
            font-style: normal;
          }
          span {
            color: #fff;
            position: relative;
            padding-left: 50px;
            font-size: 14px;
            line-height: 22px;
            text-transform: lowercase;
            &::before {
              position: absolute;
              left: 0;
              bottom: -5px;
              content: url(../img-svg/purpleTick.svg);
            }
            & + span {
              padding-top: 30px;
            }
          }
        }
      }
    }
  }
  .regulations {
    text-align: left;
    display: flex;
    justify-content: space-between;
    @include breakpoint(mobile) {
      flex-direction: column;
      width: 100%;
    }
    div {
      flex: 1;
      display: flex;
      flex-direction: column;
      @include breakpoint(mobile) {
        width: 100%;
      }
    }
    img {
      margin: 30px 0;
      width: 50%;
    }
    h4 {
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.5px;
      color: #637381;
    }
    p {
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #637381;
      opacity: 0.6;
    }
  }
}
