@import "./variables.scss";

.preLoginHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;

  @include breakpoint(mobile) {
    padding: 0px $mobileMargin;
    background-color: #eeeeee;
    position: fixed;
    top: 0;
    width: 100%;
    height: 80px;
    z-index: 11;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    transition: all 0.5s ease;

    &.active {
      background-color: #eeeeee;
    }
  }

  .menu-options {
    width: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: $mobileMargin;
    margin-right: $mobileMargin;
    white-space: nowrap;

    @include breakpoint(mobile) {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 75px;
      left: -100%;
      opacity: 0;
      transition: all 0.5s ease;
      flex-direction: column;
      list-style-type: none;
      background-color: #eeeeee;

      &.active {
        background-color: #eeeeee;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 10;
        align-content: flex-start;
        justify-content: flex-start;
        padding-top: 20px;
        margin-left: 0;
        padding-left: 20px;
        height: 95vh;
      }

      .option {
        display: flex;
        border-bottom: 2px solid rgba(0, 0, 0, 0.05);
        padding: 28px 0px;
      }
    }

    & * + * {
      margin: 0 0 0 56px;

      @include breakpoint(mobile) {
        margin: 10px 0 0 0;
      }
    }
  }

  .btn-container {
    @include breakpoint(mobile) {
      display: none;
    }
  }

  .btn-container.mobile-option {
    display: none;

    @include breakpoint(mobile) {
      align-self: center;
      display: flex;
      flex-direction: column;
      position: absolute;
      bottom: 75px;
    }
  }

  .burger-menu {
    display: none;

    @include breakpoint(mobile) {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 30px;
      height: 30px;
      cursor: pointer;
      transition: all 0.5s ease-in-out;

      &__icon {
        width: 27px;
        height: 4px;
        background-color: $btnColorPrimary;
        border-radius: 5px;
        transition: all 0.5s ease-in-out;
      }

      &__icon::before,
      &__icon::after {
        content: "";
        position: absolute;
        width: 27px;
        height: 4px;
        background-color: $btnColorPrimary;
        border-radius: 5px;
        transition: all 0.5s ease-in-out;
      }

      &__icon::before {
        transform: translateY(-10px);
      }

      &__icon::after {
        transform: translateY(10px);
      }

      &.open .burger-menu__icon {
        transform: translateX(30px);
        background-color: transparent;
      }

      &.open .burger-menu__icon::before {
        transform: translate(-30px, 0px) rotate(45deg);
      }

      &.open .burger-menu__icon::after {
        transform: translate(-30px, 0px) rotate(-45deg);
      }
    }
  }

  li {
    color: #212b36;

    @include breakpoint(mobile) {
      width: 95%;
    }
  }
}
