h1 {
  line-height: 53px;
  font-size: 2.8em;
  color: #212b36;

  @include breakpoint(mobile) {
    font-size: 26px;
    line-height: 30px;
    white-space: pre-line;
  }
}

h4 {
  font-weight: 400;
  font-size: 1.25em;
  line-height: 23px;
}

a {
  color: $btnColorPrimary;
  transition: text-shadow 0.3s;
}
a:hover {
  color: $btnColorPrimaryHover;
  cursor: pointer;
}
a:focus {
  color: $btnColorPrimary;
  text-decoration: underline;
}

// TO JEST DO ROZRZUCENIA NA POSZCEGÓLNE PLIKI
.register p a:hover,
#login form a:hover,
#login p a:hover {
  color: $btnColorPrimaryHover;
}

form {
  .formItem {
    input {
      transition: 0.5s ease-in-out;
    }
    input:focus {
      border-bottom: 1px solid #7974ff;
    }
  }
}

.errorMsg {
  margin: 0 auto;
  color: $failure;
}
small.errorMsg {
  font-size: 11px;
  color: $failure;
  opacity: 1;
}

.fullWidth {
  width: 100% !important;
}

.acceptCheckboxError {
  border: 3px solid $failure !important;
}

.link {
  cursor: pointer;
}

::selection {
  background: rgba(121, 116, 255, 0.3); /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: rgba(121, 116, 255, 0.3); /* Gecko Browsers */
}
