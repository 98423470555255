@import "./variables.scss";

.sub-nav__wrapper {
  margin-bottom: 16px;
}
#shipment-setup {
  form {
    display: flex;
    flex-direction: column;
  }
}

.shipment-date__wrapper,
.shipment-pay__wrapper {
  h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #212b36;
    padding: 25px 0 27px;
    @include breakpoint(mobile) {
      padding: 0 0 20px;
    }
  }

  .date-option__container {
    @include breakpoint(mobile) {
      flex-direction: column;
    }
    display: flex;
    justify-content: flex-start;
    gap: 15px;
    flex-wrap: wrap;
    .formItem.formItemAlert {
      flex-basis: unset;
    }
    .formItem {
      margin-bottom: 0;
      display: flex;
      flex-wrap: wrap;
      & > * + * {
        margin: 0 0 0 1rem;
      }
      @include breakpoint(mobile) {
        width: 100%;
      }
    }
  }
  .pay-option__container {
    @include breakpoint(mobile) {
      width: 100%;
    }
    .formItem {
      margin-bottom: 0;
      width: 70vw;
      display: flex;
      flex-wrap: wrap;
      & > * + * {
        margin: 0 0 0 1rem;
      }
      @include breakpoint(mobile) {
        width: 100%;
      }
    }
    .more-dates {
      width: 183px;
      height: 124px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include breakpoint(mobile) {
        width: 100%;
        height: auto;
      }
      .btn {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: #7974ff;
        background: transparent;
        padding: 0;
      }
    }

    &.selected {
      .shipment-pay-card {
        border: 1px solid $btnColorPrimary;
      }
      span {
        color: $btnColorPrimary;
      }
    }
  }
  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 30px;
    color: #212b36;
    opacity: 0.6;
  }
}
.shipment-pay__wrapper {
  margin: 55px 0;
  @include breakpoint(mobile) {
    width: 100%;
  }
}
.finalize-btn {
  width: 100%;
}
