@import "../scss/variables.scss";

#register__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16vh;
  @include breakpoint(mobile) {
    margin: 120px $mobileMargin;
    align-items: flex-start;
  }
}
.register {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  h1 {
    @include breakpoint(mobile) {
      align-self: flex-start;
      text-align: left;
    }
  }
  .services-container {
    display: flex;
    & > * + * {
      margin: 0 0 0 1rem;
    }
    @include breakpoint(mobile) {
      flex-direction: column;
      & > * + * {
        margin: 1rem 0 0 0;
      }
    }
    button {
      width: 225px;
      height: 57px;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    @include breakpoint(mobile) {
      width: 100%;
    }
    .formItem {
      width: 25.6rem;

      @include breakpoint(mobile) {
        margin-bottom: 25px;
        width: 100%;
      }
      button {
        margin: 0;
        width: 190px;
      }
    }
    .formItemAlert {
      display: flex;
      justify-content: center;
    }
  }
  p {
    font-family: Roboto;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #212b36;
    a {
      font-size: 14px;
      line-height: 22px;
      color: #7974ff;
      cursor: pointer;
    }
  }
}

#registerBackground {
  @include overflowSolution;
  @include shapePositions3;
  .rect {
    @include bottomStripe;
  }
  @include breakpoint(mobile) {
    display: none;
  }
}
