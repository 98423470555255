@import "./variables.scss";

.information-container {
  width: 40vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  & > * + * {
    margin: 18px 0 0 0;
  }
  .intro {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    color: #ffa07f;
  }
  h1 {
    @include bigHeader;
  }
  p:last-child {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    color: #212b36;
  }
}
.background-container {
  @include overflowSolution;
  overflow: hidden;
  .carousel-1-color {
    width: 32vw;
    height: 85vh;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -3;
    background-color: #56cbb9;
    box-shadow: 0px 5px 15px rgba(116, 125, 132, 0.156714);
    border-top-left-radius: 10px;
  }
  .carousel-1-image {
    img {
      width: 50vw;
      position: absolute;
      z-index: -2;
      right: 0px;
      top: 50%;
      transform: translateY(-40%);
    }
  }
  .carousel-2-color {
    width: 50vw;
    height: 85vh;
    position: absolute;
    // top: 0;
    bottom: 0;
    right: 0;
    z-index: -3;
    background: #ff9371;
    box-shadow: 0px 5px 15px rgba(116, 125, 132, 0.156714);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .carousel-2-image {
    img {
      width: 44vw;
      position: absolute;
      z-index: -2;
      right: 120px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .carousel-3-color {
    width: 40vw;
    height: 60vh;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -3;
    background: #863bff;
    box-shadow: 0px 5px 15px rgba(116, 125, 132, 0.156714);
    border-top-left-radius: 10px;
  }
  .carousel-3-image {
    img {
      width: 50vw;
      position: absolute;
      z-index: -2;
      right: -50px;
      top: 55%;
      transform: translateY(-50%);
    }
  }
}
