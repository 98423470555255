@import "./variables.scss";

.shipment-card {
  position: relative;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  line-height: 1.5rem;
  width: 100%;
  @include breakpoint(mobile) {
    width: 100%;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 15px;
  }
  .shipment-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    min-width: 183px;

    @include breakpoint(mobile) {
      padding-top: 0;
      flex-direction: row;
      width: 92%;
    }
    .span-container {
      padding-top: 20px;
      @include breakpoint(mobile) {
        padding: 0;
      }
      span {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: #212b36;
      }
    }
    p {
      padding-top: 17px;
      @include breakpoint(mobile) {
        padding: 0;
      }
      span {
        font-family: Roboto;
        font-style: italic;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: #000000;
        opacity: 0.6;
      }
    }
    .radioBox {
      margin-top: 8px;
    }
    &.selected {
      border: 1px solid $btnColorPrimary;
    }
  }
}
.shipment-card.active {
  border: 1px solid $btnColorPrimary;
  .span-container {
    span {
      color: $btnColorPrimary;
    }
  }
}
