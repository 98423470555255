@import "./variables.scss";

.share__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 75px;
  p {
    font-family: Roboto;
    font-style: italic;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #212b36;
    margin-bottom: 20px;
  }
  .share-buttons {
    display: flex;
    @include breakpoint(mobile) {
      flex-direction: column;
    }
    & > * + * {
      margin: 0 0 0 20px;
      @include breakpoint(mobile) {
        margin: 20px 0 0 0;
      }
    }
    .btn {
      width: 250px;
    }
  }
}
