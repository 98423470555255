@import "./variables.scss";
@import "./typography.scss";

.card {
  height: fit-content;
  max-height: 80vh;
  display: flex;
  justify-content: center;
  position: relative;
  height: calc(100vh - 190px);
  margin-left: auto;
  padding-left: 20px;
  min-width: 340px;

  @include breakpoint(mobile) {
    display: none;
  }

  .cardFooter {
    position: absolute;
    z-index: 1;
    bottom: 50px;
    text-align: center;
    color: $textColorPrimary;
    margin: 0 20px 0;
  }

  p {
    opacity: 0.7;
    line-height: 24px;
    margin: 0 0 25px;
  }

  .cardBgImg {
    z-index: 0;
    width: 100%;
  }
}
