@import "./variables.scss";
@import "./typography.scss";

.btn {
  height: $btnHeight;
  background-color: $btnColor;
  border-radius: $btnBorderRadius;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.5px;
  padding: 0 42px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: 0.4s;

  &.white {
    background-color: white;
    transition: 0.4s;
    border: 1px solid #000;
  }

  &.primary {
    color: $textColorPrimary;
    background-color: $btnColorPrimary;
    border: 1px solid transparent;
    transition: 0.4s;
  }

  &.outlined {
    background: none;
    border: 1px solid white;
    color: $textColorPrimary;
    padding: 0 49px;
  }

  &.blueBorder {
    background: none;
    border: 1px solid $btnColorPrimary;
    color: $btnColorPrimary;
    padding: 0 49px;
  }

  &.google,
  &.google:hover {
    background: #ffffff;
    border: 1px solid #f5f5f5;
    border-radius: 32px;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    position: relative;
  }

  &.google::before {
    position: absolute;
    left: 15%;
    top: 50%;
    transform: translateY(-50%);
    content: url(../img-svg/google.svg);
  }

  &.facebook,
  &.facebook:hover {
    background: linear-gradient(91.24deg, #077eed 12.32%, #0361dd 93.79%);
    border-radius: 32px;
    position: relative;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
  }

  &.facebook::before {
    position: absolute;
    left: 15%;
    top: 50%;
    transform: translateY(-50%);
    content: url(../img-svg/facebook.svg);
  }

  &.copy,
  &.copy:hover {
    background-color: #ffffff;
    border: 1px solid #7974ff;
    border-radius: 32px;
    position: relative;
    font-size: 18px;
    line-height: 21px;
    color: #7974ff;
  }

  &.copy::before {
    position: absolute;
    left: 15%;
    top: 50%;
    transform: translateY(-50%);
    content: url(../img-svg/copyIco.svg);
  }

  &.facebook-dark,
  &.facebook-dark:hover {
    background: #334f8d;
    border-radius: 32px;
    position: relative;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
  }

  &.facebook-dark::before {
    position: absolute;
    left: 15%;
    top: 50%;
    transform: translateY(-50%);
    content: url(../img-svg/facebook.svg);
  }

  &.twitter,
  &.twitter:hover {
    background: #4ba3eb;
    border-radius: 32px;
    position: relative;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
  }

  &.twitter::before {
    position: absolute;
    left: 15%;
    top: 50%;
    transform: translateY(-50%);
    content: url(../img-svg/twitterIco.svg);
  }

  &:disabled {
    background: #ccc;

    &:hover {
      background-color: #ccc !important;
      cursor: not-allowed;
    }
  }
}

.btn:hover {
  @include btnHover;

  &.white {
    border: 1px solid $btnColorPrimary;
    @include btnHover;
  }

  &.primary {
    @include primaryHover;
  }
}

.btn:focus {
  @include btnFocus;

  &.white {
    border: 1px solid #000;
    @include btnFocus;
  }

  &.primary {
    @include primaryFocus;
  }
}

.btn-container {
  display: flex;
  justify-content: space-between;

  & > * + * {
    margin: 0 0 0 26px;
  }
}
