@import "./variables.scss";

.container {
  display: flex;
  position: relative;
  padding-left: $checkboxSize + 12px;
  margin-bottom: 12px;
  padding-top: 4px;
  align-items: center;
  align-content: center;
  white-space: nowrap;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 21px;
  border: 1px solid $btnColorPrimary;
  opacity: 0.8;
  border-radius: 2px;
}

.container:hover input ~ .checkmark {
  border: 2px solid $btnColorPrimaryHover;
  opacity: 1;
}

.container:focus input ~ .checkmark {
  border: 2px solid $btnColorPrimaryHover;
  opacity: 1;
}

.container input:checked ~ .checkmark {
  background-color: $btnColorPrimary;
  border: 2px solid transparent;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  border: 2px solid transparent;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 5px;
  top: 0px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.container .checkmark:hover:after {
  left: 5px;
  top: 0px;
}
