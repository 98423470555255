@import "./variables.scss";

.button-background {
  align-items: center;
  background-color: #eee;
  bottom: 0;
  btn:first-child {
    margin-right: 1rem;
  }
  display: flex;
  height: 5.5rem;
  position: fixed;
  width: 100vw;
  .container {
    display: flex;
    margin: 0 $sideMargin;
    & > * + * {
      margin: 0 0 0 20px;
    }
  }
}
.button-background::before {
  bottom: 5.5rem;
  content: "";
  height: 2rem;
  position: fixed;
  width: 100vw;
}
.row-container:hover {
  cursor: pointer;
  text-decoration: underline;
}
