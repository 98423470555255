#carousel {
  .btn-container {
    display: flex;
    margin-top: 3.375rem;
    justify-content: flex-start;
    button {
      width: 200px;
      padding: 12px 0;
      & ~ button {
        margin: 0 0 0 1rem;
      }
    }
  }
}

.carousel__container {
  .carousel__slide {
    display: none;
  }
}

.carousel__control {
  margin-top: 48px;
}
.dot {
  cursor: pointer;
  height: 24px;
  width: 24px;
  margin: 0 10px;
  border: 1px solid #7974ff;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.5s ease;
}
.dot-active,
.dot:hover {
  background: #7974ff;
  border: 1px solid #7974ff;
}

.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 0.5s;
  animation-name: fade;
  animation-duration: 0.5s;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}
