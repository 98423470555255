@import "../scss/variables.scss";

#registerCorrect__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16vh;
  @include breakpoint(mobile) {
    margin: 120px $mobileMargin 0;
  }
}

#registerCorrect {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .header-container {
    width: 40vw;
    @include breakpoint(mobile) {
      width: 90vw;
    }
    h1 {
      // @include bigHeader;
      text-align: center;
      margin: 0 0 2.5rem;
      @include breakpoint(mobile) {
      }
    }
    p {
      max-width: 75vw;
      font-size: 18px;
      line-height: 30px;
      text-align: center;
      color: #212b36;
      @include breakpoint(mobile) {
        max-width: 90vw;
      }
    }
  }
  .btn-container {
    display: flex;
    flex-direction: column;
    margin-top: 5.625rem;
    & > * + * {
      margin: 1rem 0 0 0;
    }
    @include breakpoint(mobile) {
      margin-top: 25px;
    }
    button {
      width: 200px;
      padding: 12px 0;
    }
  }
}

.greenBackground {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: #56cbb9;
  // background-image: url(../img-svg/bigHand.png);
  background-repeat: no-repeat;
  background-position: right 1% bottom -100px;
  box-shadow: 0px 5px 15px rgba(116, 125, 132, 0.156714);
  overflow-y: hidden;
  @include breakpoint(mobile) {
    width: 100%;
    height: 100%;
  }
}

@-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

.animation {
  position: absolute;
  right: 1%;
  top: 100vh;
  animation: animationFrames linear 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  transform-origin: 50% 50%;
  -webkit-animation: animationFrames linear 1s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-transform-origin: 50% 50%;
  -moz-animation: animationFrames linear 1s;
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-transform-origin: 50% 50%;
  -o-animation: animationFrames linear 1s;
  -o-animation-iteration-count: 1;
  -o-animation-fill-mode: forwards;
  -o-transform-origin: 50% 50%;
  -ms-animation: animationFrames linear 1s;
  -ms-animation-iteration-count: 1;
  -ms-animation-fill-mode: forwards;
  -ms-transform-origin: 50% 50%;
  @include breakpoint(mobile) {
    width: 300px;
    right: 35%;
    top: 100vh;
    animation: animationFramesMobile linear 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    transform-origin: 50% 50%;
    -webkit-animation: animationFramesMobile linear 1s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
    -webkit-transform-origin: 50% 50%;
    -moz-animation: animationFramesMobile linear 1s;
    -moz-animation-iteration-count: 1;
    -moz-animation-fill-mode: forwards;
    -moz-transform-origin: 50% 50%;
    -o-animation: animationFramesMobile linear 1s;
    -o-animation-iteration-count: 1;
    -o-animation-fill-mode: forwards;
    -o-transform-origin: 50% 50%;
    -ms-animation: animationFramesMobile linear 1s;
    -ms-animation-iteration-count: 1;
    -ms-animation-fill-mode: forwards;
    -ms-transform-origin: 50% 50%;
  }
}
$animationStartX: 0px;
$animationStartY: 0px;
$animationEndX: 0px;
$animationEndY: -70vh;

$animationMobileStartX: 0px;
$animationMobileStartY: 0px;
$animationMobileEndX: 0px;
$animationMobileEndY: -100%;

@keyframes animationFrames {
  0% {
    transform: translate($animationStartX, $animationStartY);
  }
  100% {
    transform: translate($animationEndX, $animationEndY);
  }
}

@-moz-keyframes animationFrames {
  0% {
    -moz-transform: translate($animationStartX, $animationStartY);
  }
  100% {
    -moz-transform: translate($animationEndX, $animationEndY);
  }
}

@-webkit-keyframes animationFrames {
  0% {
    -webkit-transform: translate($animationStartX, $animationStartY);
  }
  100% {
    -webkit-transform: translate($animationEndX, $animationEndY);
  }
}

@-o-keyframes animationFrames {
  0% {
    -o-transform: translate($animationStartX, $animationStartY);
  }
  100% {
    -o-transform: translate($animationEndX, $animationEndY);
  }
}

@-ms-keyframes animationFrames {
  0% {
    -ms-transform: translate($animationStartX, $animationStartY);
  }
  100% {
    -ms-transform: translate($animationEndX, $animationEndY);
  }
}

@keyframes animationFramesMobile {
  0% {
    transform: translate($animationMobileStartX, $animationMobileStartY);
  }
  100% {
    transform: translate($animationMobileEndX, $animationMobileEndY);
  }
}
