@import "./variables.scss";
@import "./login-base.scss";

.text-content {
  margin-bottom: 30px;
}

#settings {
  h3 {
    @include smallHeader;
    margin-bottom: 15px;
  }
  p {
    @include basicP;
  }
}

.finalize-btn {
  margin-bottom: 70px;
  .btn {
    padding: 12px 90px;
    @include breakpoint(mobile) {
      padding: 12px 0;
      width: 100%;
    }
  }
}

.settings-option__container {
  margin-bottom: 30px;

  .formItem {
    margin-bottom: 0;
    width: 70vw;
    display: flex;
    flex-wrap: wrap;
    & > * + * {
      margin: 0 0 0 1rem;
      @include breakpoint(mobile) {
        margin: 0;
      }
    }
    @include breakpoint(mobile) {
      width: 100%;
    }
  }
}

.notifications {
  form {
    .formItem {
      margin-bottom: 0;
      .container {
        width: 30vw;
        border: 1px solid #f0f0f0;
        border-radius: 8px;
        padding: 10px 40px;
        @include breakpoint(mobile) {
          width: 100%;
        }
      }
    }
    .checkmark {
      background-color: #fff;
      top: 7px;
      left: 10px;
    }
  }
}
