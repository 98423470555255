@mixin breakpoint($point) {
  @if $point == medium {
    @media (max-width: 1366px) {
      @content;
    }
  }
  @if $point == mobile {
    @media (max-width: 40.625rem) {
      @content;
    }
  }
  @if $point == smallMobile {
    @media (max-width: 21.875rem) {
      @content;
    }
  }
}

//WhyUs
@mixin shadow-radius {
  box-shadow: 0px 5px 15px rgba(116, 125, 132, 0.156714);
  border-radius: 10px;
}
@mixin cardCentering {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 65%;
  text-align: left;
  flex: 1;
}

@mixin WhyUsCardHeaderTypo {
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 30px;
  color: $textColorPrimary;
}

@mixin WhyUsCardTextTypo {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

//Reviews

@mixin reviewFont {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

// Knowledge

@mixin bigHeader {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 2.813rem;
  line-height: 3.313rem;
  color: $textColorBlack;
}

@mixin pSuccess {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  color: $textColorBlack;
}

@mixin basicP {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $textColorBlack;
}

@mixin smallHeader {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  color: #212b36;
}

@mixin bottomStripe {
  position: absolute;
  left: 0%;
  right: 0%;
  bottom: 0;
  height: 3vh;
  background: #863bff;
  box-shadow: 0px 5px 15px rgba(116, 125, 132, 0.156714);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

@mixin overflowSolution {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
}

@mixin shapePositions1 {
  .greenRect {
    position: absolute;
    bottom: -100px;
    left: -60px;
  }

  .pinkCircle {
    position: absolute;
    left: 151px;
    top: 500px;
  }

  .whiteFig {
    position: absolute;
    bottom: -120px;
    left: 30vw;
  }

  .greenBall {
    position: absolute;
    top: 40vh;
    right: -60px;
  }

  .whiteCube {
    position: absolute;
    bottom: 1vh;
    right: -100px;
  }
  .pinkShape2 {
    position: absolute;
    left: 60vw;
    bottom: -12vh;
  }
}
@mixin shapePositions2 {
  .greenRect {
    position: absolute;
    top: 400px;
    right: -40px;
  }
  .pinkCircle {
    position: absolute;
    left: -120px;
    top: 320px;
  }
  .whiteFig {
    position: absolute;
    bottom: -14vh;
    left: 27vw;
  }
  .greenBall {
    position: absolute;
    top: 100px;
    right: -130px;
  }
  .whiteCube {
    position: absolute;
    bottom: 8vh;
    left: -9vw;
  }
  .pinkShape2 {
    position: absolute;
    left: 43vw;
    bottom: -14vh;
  }
}
@mixin shapePositions3 {
  .greenRect {
    position: absolute;
    bottom: -100px;
    left: -60px;
    @include breakpoint(mobile) {
      transform: scale(0.8);
    }
  }

  .pinkCircle {
    position: absolute;
    left: -8%;
    top: 70%;
    @include breakpoint(mobile) {
      transform: scale(0.8);
    }
  }
  .whiteFig {
    position: absolute;
    bottom: -14vh;
    left: 27vw;
  }
  .pinkShape1 {
    position: absolute;
    right: -13vw;
    top: 25vh;
  }
  .greenBall {
    position: absolute;
    top: 45vh;
    right: -5vw;
    z-index: 1;
  }
  .whiteCube {
    position: absolute;
    top: 22vh;
    left: -8vw;
  }
  .pinkShape2 {
    // transform: rotate(90deg);
    position: absolute;
    left: -7vw;
    top: 45vh;
  }
  .blueDrop {
    position: absolute;
    right: -80px;
    bottom: -150px;
  }
}

//buttons states

// primary
@mixin primaryHover {
  background-color: $btnColorPrimaryHover;
  color: #fff;
  transition: 0.4s;
}
@mixin primaryFocus {
  border: 1px solid $btnColorPrimaryHover;
  transition: 0.4s;
  outline: 0px solid transparent;
}
// basic gray
@mixin btnHover {
  background: #f3f2fa;
  color: $btnColorPrimary;
  transition: 0.4s;
}
@mixin btnFocus {
  border: 1px solid $btnColorPrimary;
  // overflow: hidden;
  // outline: 0px solid transparent;
}
// basic white

// small font in orders details

@mixin smallTag {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 21px;
  color: $textColorBlack;
}

@mixin blackRoboto {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  color: $textColorBlack;
}
