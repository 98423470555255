@import "./variables.scss";

#shipmentDetails {
  .shipment__wrapper {
    padding-top: 47px;
    @include breakpoint(mobile) {
      padding-top: 0;
    }
    .shippingAddressPicker {
      max-width: 100%;
      display: grid;
      grid-template-columns: repeat(4, minmax(20%, 25%));
      column-gap: 16px;
      row-gap: 16px;
      line-height: 24px;
      @include breakpoint(medium) {
        grid-template-columns: repeat(2, minmax(40%, 50%));
      }

      .countryCode {
        display: flex;
        align-items: center;

        img {
          margin-right: 7px;
          height: 22px;
          width: 22px;
        }
      }
      &.selected {
        .shippingAddress:not(.active):not(:hover) {
          opacity: 0.5;
          transition: opacity 0.3s, background-color 0.3s;
          background-color: #fcfdfe;
        }
        .shippingAddress {
          opacity: 1;
          border: 1px solid #7974ff;
          transition: opacity 0.3s;
        }
      }
    }

    .shippingAddress {
      padding: 16px;
      border: 1px solid #e9edf4;
      border-radius: 6px;

      p {
        font-size: 16px;
        font-weight: 400;
        word-break: break-all;
      }

      &:hover {
        border: 1px solid #7974ff;
        cursor: pointer;
      }

      .flag {
        margin-right: 8px;
      }
    }
    .order__data {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      margin-top: 50px;
      .section__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .buttons {
          @include breakpoint(mobile) {
            display: none;
          }
          .btn:after {
            content: url(../img-svg/PlusSymbolDark.svg);
            margin-left: 10px;
          }
          .btn:hover:after {
            content: url(../img-svg/PlusSymbolPurple.svg);
          }
        }
      }
      .content {
        position: relative;
        display: flex;
        padding-top: 45px;
        justify-content: space-between;
        margin-bottom: 100px;
        @include breakpoint(mobile) {
          margin-bottom: 0;
          padding-top: 0px;
          flex-direction: column;
          width: 100%;
        }
        main {
          width: 45vw;
          @include breakpoint(mobile) {
            width: 100%;
          }
          form {
            display: flex;
            flex-wrap: wrap;
            margin-top: 50px;
            justify-content: space-between;
            .select {
              display: flex;
              @include breakpoint(mobile) {
                width: 100%;
              }
            }
            .check-container {
              display: flex;
              align-items: center;
              p,
              a {
                font-size: 14px;
                line-height: 22px;
                color: #212b36;
              }
              a {
                color: $btnColorPrimary;
              }
            }
            h2 {
              font-size: 24px;
              line-height: 28px;
              color: #212b36;
              padding-bottom: 20px;
              @include breakpoint(mobile) {
                padding-top: 25px;
              }
            }
            input {
              max-width: unset;
            }
            select {
              display: none;
            }
            .small {
              max-width: 50px;
              margin-left: 5px;
              opacity: 0.5;
              font-size: 0.875rem;
            }

            .inputGroup {
              display: flex;

              span {
                opacity: 0.5;
              }
            }

            .formItem {
              margin-bottom: 40px;
              width: 49%;
              .btn-container {
                display: flex;
                justify-content: flex-start;
                @include breakpoint(mobile) {
                  align-items: center;
                  flex-direction: column;
                  margin-bottom: 40px;
                }
                .btn {
                  width: 250px;
                  @include breakpoint(mobile) {
                    width: 75%;
                  }
                }
              }
              .radio-container {
                display: flex;
              }
              label {
                &.container {
                  font-size: 0.875rem;
                  border: 2px solid transparent;
                  width: fit-content;
                  a,
                  span {
                    font-size: 0.875rem;
                    padding-left: 1ch;
                    padding-right: 1ch;
                    color: $btnColorPrimary;
                  }
                }
              }
              &.formItemAlert {
                flex-basis: 100%;
              }
            }
            .formItemAlert {
              @include breakpoint(mobile) {
                margin-bottom: 20px;
              }
            }
            .parcel-select__container {
              display: flex;
              width: 100%;
              & > * + * {
                margin: 0 0 0 32px;
              }
              @include breakpoint(mobile) {
                flex-direction: column;
                & > * + * {
                  margin: 32px 0 24px 0px;
                }
              }
              .formItem {
                @include breakpoint(mobile) {
                  margin-bottom: 0;
                  width: 100%;
                }
              }
            }
            .parcel-properties__container {
              display: flex;
              width: 100%;
              flex-direction: column;
              & > * + * {
                margin: 0 0 0 15px;
              }
              @include breakpoint(mobile) {
                flex-direction: column;
                margin: 15px 0;
                & > * + * {
                  margin: 15px 0 0 0px;
                }
                .formItem {
                  margin: 0;
                }
              }
              .size {
                display: flex;
                width: 100%;
                justify-content: space-between;
                .separator {
                  @include breakpoint(mobile) {
                    padding: 0 5px;
                  }
                }
                @include breakpoint(mobile) {
                  width: 100%;
                  .formItem {
                    width: 100%;
                  }
                }
              }
              .weight {
                width: 25%;
                margin-left: 0;
                @include breakpoint(mobile) {
                  width: 100%;
                  margin-bottom: 30px;
                }
                @include breakpoint(smallMobile) {
                  margin-bottom: 30px;
                  margin-left: 0;
                }
              }
              .formItem {
                width: 100%;
              }
            }
          }
          .questionMark {
            display: flex;
            align-items: center;
            margin: 0 0 20px;
            cursor: pointer;
            .qestionIcon {
              fill: $btnColorPrimary;
            }
            & > * + * {
              margin: 0 0 0 10px;
              color: $btnColorPrimary;
              text-decoration: underline;
            }
          }
        }
        .order__summary {
          position: sticky;
          top: 0px;
          bottom: 50px;
          right: $sideMargin;
          width: 500px;
          height: 500px;
          background-image: url(../img-svg/priceListShape.svg);
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          @include breakpoint(mobile) {
            background-image: none;
            position: unset;
            top: 0;
            right: 0;
            transform: translate(unset);
            width: 100%;
            height: auto;
            margin: 0;
            background-color: white;
          }
          div {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            @include breakpoint(mobile) {
              width: 100%;
              height: 100%;
              transform: translate(0, 0);
              position: unset;
              padding: 12px 0;
              border-top: 2px solid rgba($color: #000000, $alpha: 0.08);
              border-bottom: 2px solid rgba($color: #000000, $alpha: 0.08);
            }
          }
          p {
            font-family: Roboto;
            font-style: italic;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            color: #000000;
            margin-bottom: 15px;
            span {
              opacity: 0.5;
            }
            &.upper {
              text-transform: uppercase;
              span {
                opacity: 0.3;
              }
            }
          }
          h1 {
            color: #56cbb9;
            margin-bottom: 20px;
          }
          &.hidden-mobile {
            @include breakpoint(mobile) {
              display: none;
            }
          }
          &.hidden-deskop {
            display: none;
            @include breakpoint(mobile) {
              display: unset;
            }
          }
          .informationHidden {
            width: 484px;
            height: 436px;
            border-radius: 10px;
            opacity: 0;
            transition: all 0.3s ease;
            cursor: default;
            &.informationActive {
              background-color: $btnColorPrimary;
              opacity: 1;
              z-index: 1000;
            }
            .plusButton {
              position: relative;
              cursor: pointer;
              transition: all 0.5s ease-in-out;
              z-index: 10000;
              &__icon {
                cursor: pointer;
                top: 27px;
                right: 20px;
                left: unset;
                width: 27px;
                height: 4px;
                background-color: #fff;
                border-radius: 5px;
                transition: all 0.5s ease-in-out;
                transform: rotate(45deg);
              }
              &__icon::before {
                content: "";
                position: absolute;
                width: 27px;
                height: 4px;
                transform: rotate(90deg);
                background-color: #fff;
                border-radius: 5px;
                transition: all 0.5s ease-in-out;
              }
            }
            .parcelInfo {
              width: 65%;
              padding: 35px;
              top: 0;
              left: 0;
              transform: unset;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              font-style: normal;
              p {
                font-size: 24px;
                line-height: 28px;
                color: #fff;
                margin-bottom: 40px;
                font-style: normal;
              }
              span {
                color: #fff;
                position: relative;
                padding-left: 50px;
                font-size: 14px;
                line-height: 22px;
                text-transform: lowercase;
                &::before {
                  position: absolute;
                  left: 0;
                  bottom: -5px;
                  content: url(../img-svg/purpleTick.svg);
                }
                & + span {
                  padding-top: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
}

#shipment > div:nth-child(28) {
  margin-bottom: 0px;
}
#shipment > div:nth-child(30) {
  margin-bottom: 0px;
}

.shipmentBackground {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  background: linear-gradient(180deg, rgba(238, 238, 238, 0) 0%, #eeeeee 100%);
  z-index: -1;
  transform: rotate(-180deg);
}
