@import "./variables.scss";

main {
  min-width: 480px;
}
.inputWrapper {
  width: 100%;

  .inputBox {
    position: relative;
    width: 100%;
    min-width: 128px;
    margin-bottom: 45px;
    display: inline-block;
    white-space: nowrap;

    input:-webkit-autofill {
      box-shadow: 0 0 0 30px #fff inset !important;
      -webkit-box-shadow: 0 0 0 30px #fff inset !important;
    }

    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      box-shadow: 0 0 0 30px #f9f9ff inset !important;
      -webkit-box-shadow: 0 0 0 30px #f9f9ff inset !important;
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      border: 1px solid #e9edf4;
      border-radius: 6px;
      background: #fff;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #212b36;
      transition: 0.3s ease-in-out;
      padding: 13px 16px;

      @include breakpoint(mobile) {
        max-width: 100%;
      }

      &.invalid {
        border-bottom: 1px solid red !important;
      }
    }

    input.small {
      width: 100px;
    }

    span {
      position: absolute;
      top: 1px;
      left: 1px;
      padding: 13px 16px;
      display: inline-block;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #212b36;
      opacity: 0.5;
      transition: 0.5s;
      pointer-events: none;
    }

    input:hover {
      background-color: #f9f9ff;
    }

    input:focus,
    input:not(:placeholder-shown) {
      padding: 20px 16px 6px;
    }

    input:focus {
      border-bottom: 1px solid #7974ff;
      background-color: #f9f9ff;
    }

    input:focus ~ span,
    input:not(:placeholder-shown) ~ span {
      transform: translate(0px, -10px);
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 22px;
      color: #212b36;
      opacity: 0.5;
    }
  }

  small.errorMsg {
    left: 0;
    top: 50px;
  }
}

.passwordWrapper {
  width: 100%;
  position: relative;

  .eyeIcon {
    fill: $btnColorPrimary;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    transition: opacity 0.3s ease;

    &.semi {
      opacity: 0.5;
    }

    &.with-error {
      top: calc(50% - 12.5px);
    }
  }

  .reminder {
    position: absolute;
    margin-top: 4px;
    right: 0px;
  }
}
