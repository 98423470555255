@import "./variables.scss";

.shipment-pay-card {
  position: relative;
  width: 183px;
  height: 192px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  @include breakpoint(mobile) {
    height: 53px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid #f0f0f0;
    display: flex;
    justify-content: flex-end;
  }
  .shipment-pay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    @include breakpoint(mobile) {
      width: 92%;
      height: 100%;
      flex-direction: row;
    }
    span {
      padding-top: 20px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;
      color: #212b36;
      @include breakpoint(mobile) {
        padding: 15px;
      }
    }
    .logo {
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      @include breakpoint(mobile) {
        padding: 0;
        margin-right: 15px;
      }

      img {
        max-width: 70px;
        max-height: 70px;
        @include breakpoint(mobile) {
          width: 30px;
        }
      }
    }
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    @include breakpoint(mobile) {
      left: 25px;
      top: 17px;
    }
  }
}
.shipment-pay-card.active {
  border: 1px solid $btnColorPrimary;
  span {
    color: $btnColorPrimary;
  }
}
