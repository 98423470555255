@import "./variables.scss";

.loginHeader__topNav {
  margin: 0 $sideMargin;
}

.background {
  background-color: #fff;
}

#dashboard,
#settings,
#to-send,
#orders,
#parcel-details,
#invoice,
#user-data,
#balance-history {
  min-height: 70vh;
  margin: 50px $sideMargin 45px;
  @include breakpoint(mobile) {
    margin: 120px $mobileMargin 0;
  }
}

.sub-nav__wrapper {
  margin-bottom: 75px;
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .search__container {
      margin: 0 20px;
    }
    @include breakpoint(mobile) {
      flex-wrap: wrap;

      .search__container {
        order: 2;
        flex: 1 0 100%;
        margin: 20px 0;
      }
    }
  }
  .search__container {
    min-width: 25vw;
    height: 47px;
    .inputBox {
      width: 100%;
      input {
        height: 47px;
      }
    }
  }

  .btn-container {
    display: flex;
    justify-content: space-between;
    .btn,
    .primary {
      white-space: nowrap;
    }
    .btn::after {
      content: url(../img-svg/PlusSymbolDark.svg);
      margin-left: 10px;
    }
    .btn:hover::after {
      content: url(../img-svg/PlusSymbolPurple.svg);
      margin-left: 10px;
    }
    .btn.primary::after {
      content: url(../img-svg/PlusSymbol.svg);
      margin-left: 10px;
    }
    @include breakpoint(mobile) {
      display: none;
    }
  }

  .mobile {
    display: none;
    @include breakpoint(mobile) {
      display: block;
    }
  }
}
