@import "./variables.scss";

#shipmentFinalization {
  .shipment__wrapper {
    padding-top: 47px;
    @include breakpoint(mobile) {
      padding-top: 0;
    }
  }
}
.shipmentBackground {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  background: linear-gradient(180deg, rgba(238, 238, 238, 0) 0%, #eeeeee 100%);
  z-index: -1;
  transform: rotate(-180deg);
}
