@import "./variables.scss";

.integration-card {
  position: relative;
  width: 183px;
  height: 192px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  @include breakpoint(mobile) {
    height: 53px;
    width: 100%;
  }
  .integration {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    @include breakpoint(mobile) {
      width: 100%;
      height: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
  }
  .company {
    font-weight: 500;
    padding-top: 20px;
    font-family: Roboto;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #212b36;
    @include breakpoint(mobile) {
      padding: 0 0 0 0px;
      order: 2;
    }
  }
}
.logo {
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  img {
    width: 100%;
  }
  @include breakpoint(mobile) {
    position: unset;
    width: 40px;
    order: 3;
    margin: 0 25px 0 auto;
    transform: unset;
  }
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 30px;
  @include breakpoint(mobile) {
    margin-bottom: 20px;
    left: 25px;
    transform: translateX(0);

    order: 1;
  }
}
.connected {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  @include breakpoint(mobile) {
    position: unset;
    margin: 0;
  }
  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #56cbb9;
    padding-top: 0;
    margin-left: 5px;
  }
}

.integration-card.active {
  border: 1px solid $btnColorPrimary;
  span {
    color: $btnColorPrimary;
  }
}
