@import "./variables.scss";
* {
  .article__container {
    margin: 0 335px;
    @include breakpoint(mobile) {
      margin: 50px 30px;
    }
    .main-article {
      display: flex;
      flex-direction: column;
      .article-data {
        img {
          width: 100%;
          height: auto;
          margin-bottom: 50px;
        }
        img.normal-size {
          width: auto;
          height: auto;
          margin-bottom: 0;
        }
        .article-time {
          @include basicP;
          color: #212b36;
          opacity: 0.6;
          margin-bottom: 14px;
        }
        .article-time::before {
          content: url(../img-svg/clock.svg);
          padding-right: 12px;
          position: relative;
          top: 2px;
        }
        h1 {
          @include bigHeader;
          margin-bottom: 26px;
        }
        .article-short {
          @include basicP;
          margin-bottom: 14px;
        }
        .article-author {
          @include basicP;
          color: #212b36;
          opacity: 0.6;
          margin-bottom: 45px;
        }
        .article-content {
          section {
            h2 {
              margin: 0;
              text-align: left;
            }
            ul {
              margin: 20px 0 0 50px;
            }
            p {
              margin: 30px 0;
            }
            .player-wrapper {
              position: relative;
              padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
              .react-player {
                position: absolute;
                top: 0;
                left: 0;
              }
            }
          }
        }
      }
    }
  }

  h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 29px;
    color: #212b36;
    padding: 50px 0 0;
  }

  .articles-background {
    width: 100vw;
    background: linear-gradient(0deg, rgba(238, 238, 238, 0) 0%, #eeeeee 100%);
    h2 {
      text-align: center;
    }
    .articles-container {
      display: flex;
      flex-direction: column;
      margin: 0 $sideMargin;
      @include breakpoint(mobile) {
        margin: 0 30px;
      }
    }
  }
}
