@import "../scss/variables.scss";

#footer {
  background-color: #212b36;
}
.footer__wrapper {
  margin: 0 $sideMargin;
  @include breakpoint(mobile) {
    margin: 0 $mobileMargin;
  }
  .footer-header {
    @include breakpoint(mobile) {
      .preLoginHeader {
        flex-direction: column;
        background-color: transparent;
        position: unset;
        .logos {
          align-self: flex-start;
        }
        .btn-container {
          display: flex;
          margin-top: 20px;
          z-index: 9;
        }
        .burger-menu {
          display: none;
        }
        &.active {
          position: unset;
        }
      }
      .loginHeader {
        display: static;
      }
    }
    .btn {
      border: 1px solid transparent;
      @include breakpoint(smallMobile) {
        padding: 10px 20px;
      }
    }
  }
  section {
    display: flex;
    @include breakpoint(mobile) {
      flex-direction: column;
      margin-top: 0px;
    }
    margin-bottom: 100px;
    main {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding-right: 200px;
      @include breakpoint(mobile) {
        padding-right: 0;
      }
      p {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 30px;
        color: #d8d8d8;
        opacity: 0.7;
        padding-bottom: 40px;
        white-space: break-spaces;
      }
      div > p {
        opacity: 1;
      }
    }
    aside {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
  }
  footer {
    padding-bottom: 40px;
    display: flex;
    justify-content: space-between;
    @include breakpoint(mobile) {
      flex-direction: column;
      div:first-child {
        display: flex;
        flex-direction: column;
      }
      div:last-child {
        text-align: center;
      }
    }
    a {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;
      color: #d8d8d8;
      padding-right: 2.5rem;
      @include breakpoint(mobile) {
        margin-bottom: 20px;
      }
    }
    span {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;
      color: #d8d8d8;
      opacity: 0.4;
    }
  }
}
