.pdf-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
  .pdf-control {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    & > * + * {
      margin: 20px 0 0 0;
    }

    &__buttons {
      display: flex;
      .btn:disabled.btn:hover {
        background-color: #ededed;
        color: lightgray;
        cursor: unset;
      }
      & > * + * {
        margin: 0 0 0 20px;
      }
    }
  }
}
