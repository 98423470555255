@import "./variables.scss";

.col {
  text-align: left;
}

#dashboard {
  .flex {
    display: flex;

    &.column {
      flex-direction: column;
    }
  }
  .table-container {
    & * > img {
      padding-right: 10px;
      position: relative;
      top: 3px;
    }
    & * > span {
      color: #212b36;
      font-family: Rubik;
      font-size: 14px;
      line-height: 21px;
    }
    .header-container {
      align-items: left;
      border-bottom: 1px solid #e8ecef;
      display: grid;
      gap: 20px;
      grid-template-columns: repeat(12, 1fr);
      padding: 14px 0;
      @include breakpoint(mobile) {
        display: none;
      }
      & * > span {
        color: #252631;
        font-family: Rubik;
        font-size: 14px;
        line-height: 19px;
        opacity: 0.7;
        text-transform: uppercase;
      }
    }
    .row-container {
      align-items: flex-start;
      border-bottom: 1px solid #e8ecef;
      display: grid;
      gap: 20px;
      grid-template-columns: repeat(12, 1fr);
      padding: 24px 0;
      text-decoration: none;
      &:hover {
        background-color: rgba(121, 116, 255, 0.04);
      }
      @include breakpoint(mobile) {
        grid-template-columns: repeat(4, 1fr);
      }
      & > * {
        align-items: flex-start;
        display: flex;
      }
    }
    .col-1 {
      grid-column: 1 / 4;
      @include breakpoint(mobile) {
        grid-column: 1 / 3;
        order: 1;
      }
    }
    .col-2 {
      grid-column: 4 / 6;
      position: relative;
      & > .collection {
        position: absolute;
        top: -14px;
      }
      @include breakpoint(mobile) {
        grid-column: 1 / 3;
        order: 4;
      }
    }
    .col-3 {
      grid-column: 7 / 8;
      @include breakpoint(mobile) {
        grid-column: 3 / 4;
        order: 2;
        justify-content: flex-end;
      }
    }
    .col-4 {
      grid-column: 9 / 12;
      @include breakpoint(mobile) {
        grid-column: 3 / 5;
        order: 5;
        padding-top: 10px;
      }
    }
    .col-5 {
      grid-column: 12 / 13;
      justify-content: flex-end;
      @include breakpoint(mobile) {
        grid-column: 4 / 5;
        order: 3;
      }
    }
  }
}
