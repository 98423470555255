@import "../scss/variables.scss";

#knowledge-base {
  .knowledge__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    .knowledge__header {
      display: none;
      @include breakpoint(mobile) {
        display: unset;
        text-align: center;
        margin-bottom: 60px;
        h1 {
          padding-bottom: 20px;
        }
      }
    }
    .knowledge__container {
      display: flex;
      @include breakpoint(mobile) {
        flex-direction: column;
      }
      main {
        flex: 2;
        max-width: 670px;
        margin-right: 30px;
        display: flex;
        flex-direction: column;
      }
      aside {
        flex: 1;
        h3 {
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          font-size: 25px;
          line-height: 29px;
          color: #212b36;
          margin-bottom: 30px;
        }
      }
    }
    .articles-background {
      width: 100vw;
      background: linear-gradient(
        0deg,
        rgba(238, 238, 238, 0) 0%,
        #eeeeee 100%
      );
      .articles-container {
        margin: 0 $sideMargin;
        @include breakpoint(mobile) {
          margin: 0 $mobileMargin;
        }
      }
    }
  }
}
