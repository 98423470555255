@import "./variables.scss";
#parcel-details {
  .parcel__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 45px;
    @include breakpoint(mobile) {
      width: 100%;
    }
    .parcel-number__container {
      display: flex;
      align-items: center;
      & > * + * {
        margin: 0 0 0 25px;
      }
      @include breakpoint(mobile) {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        & > * + * {
          margin: 20px 0 0 0px;
        }
      }
      span {
        @include smallTag;
        @include breakpoint(mobile) {
          width: fit-content;
          overflow: scroll;
        }
      }
      .parcel-number {
        display: flex;
        justify-content: space-between;
        background: #f0f0f0;
        border: 1px solid #e9edf4;
        border-radius: 6px;
        padding: 13px 20px;
        & > * + * {
          margin: 0 0 0 40px;
        }
        @include breakpoint(mobile) {
          width: 100%;
        }
        span {
          @include blackRoboto;
          font-size: $fontSize16;
          line-height: 22px;
        }
      }
    }
    .btn-help {
      @include breakpoint(mobile) {
        display: none;
      }
    }
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    & > * + * {
      margin: 0 0 0 10%;
    }
    @include breakpoint(mobile) {
      flex-direction: column-reverse;
      width: 100%;
      & > * + * {
        margin: 0 0 0 0;
      }
    }
    h4 {
      @include smallTag;
      opacity: 0.7;
      margin-bottom: 0.5rem;
    }
    p {
      @include smallTag;
      margin-bottom: 10px;
    }
    img {
      margin-bottom: 10px;
    }

    &__section {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 50%;
      @include breakpoint(mobile) {
        width: 100%;
      }
      .data-company {
        display: flex;
        flex-direction: column;
        width: 30%;
        @include breakpoint(mobile) {
          flex-direction: column-reverse;
          width: 100%;
          & > * + * {
            margin: 48px 0 0 0;
          }
        }
        .sender {
          margin-bottom: 2rem;
        }
      }
      .data-shipment {
        display: flex;
        flex-direction: column;
      }
    }
    &__docs {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin: 50px 0;
      h4 {
        margin-bottom: 1.3rem;
      }
      .documents__options {
        display: flex;
        flex-direction: column;
        & > * + * {
          margin: 1rem 0 0 0;
        }
        .btn {
          padding-left: 20px;
          position: relative;
          min-width: 210px;
        }
        .print::after,
        .download::after {
          position: absolute;
          right: 15px;
          top: 12px;
        }
        .print::after {
          content: url(../img-svg/printerIcon.svg);
        }
        .download::after {
          content: url(../img-svg/downloadIcon.svg);
        }
        .print:hover::after {
          content: url(../img-svg/printerIconPurple.svg);
        }
        .download:hover::after {
          content: url(../img-svg/downloadIconPurple.svg);
        }
      }
    }
    .content__aside {
      @include breakpoint(mobile) {
        display: flex;
        flex-direction: column-reverse;
      }
      .content-history {
        margin-bottom: 5rem;
        @include breakpoint(mobile) {
          margin-top: 40px;
          span,
          p {
            margin-left: 30px;
          }
        }
        h4 {
          margin-bottom: 2rem;
        }
        p {
          opacity: 0.7;
          padding-top: 5px;
          position: relative;
          &::before {
            content: "";
            width: 40px;
            height: 2px;
            background-color: #66676f;
            opacity: 0.2;
            position: absolute;
            top: 50%;
            left: -45px;
            transform: rotate(90deg);
          }
          &:last-child::before {
            display: none;
          }
        }
        .inProgress,
        .received,
        .required,
        .toPickedUp,
        .canceled {
          position: relative;
        }
        .inProgress::before,
        .received::before,
        .warn:before,
        .canceled::before {
          content: "";
          width: 10px;
          height: 10px;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: -30px;
        }
        .inProgress::before {
          background-color: #ffab2b;
        }
        .received::before {
          background-color: #13c296;
        }
        .warn::before {
          background-color: #d80027;
        }
        .canceled::before {
          background-color: darkgray;
        }
      }
      .summary {
        @include breakpoint(mobile) {
          .btn {
            width: 100%;
          }
        }
        p {
          width: 400px;
          margin: 20px 0 52px;
          a {
            font-size: inherit;
          }
          @include breakpoint(mobile) {
            width: 100%;
          }
        }
      }
      .summary.warning {
        border-left: 5px solid #d80027;
        padding-left: 30px;
      }
      .hidden {
        display: none;
        &.warning {
          display: block;
        }
      }

      .warning {
        visibility: visible;
        margin-bottom: 20px;
        img {
          width: 48px;
          height: 48px;
          margin: 0;
        }
      }
    }
  }
  .collection,
  .delivery {
    position: relative;
    padding-left: 20px;
  }
  .collection::before,
  .delivery::before {
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
  }

  .collection::before {
    content: url(../img-svg/location.svg);
  }
  .delivery::before {
    content: url(../img-svg/flag.svg);
  }
  .mobile-hidden {
    display: none;
    @include breakpoint(mobile) {
      display: flex;
      justify-content: center;
      margin-bottom: 40px;
    }
  }
}
