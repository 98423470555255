.chips {
  display: flex;
  margin-top: 35px;
  margin-bottom: 43px;
  flex-wrap: wrap;
  gap: 12px;
}
.chip {
  cursor: pointer;
  font-weight: 500;
  padding: 0 17px;
  height: 47px;
  display: flex;
  align-items: center;
  background: #ededed;
  border: none;
  border-radius: 8px;
  margin: 4px 4px 0 0;
  justify-content: center;
  &.small {
    font-family: "Rubik", sans-serif;
    max-width: max-content;
    text-align: center;
    font-weight: 400;
    padding: 10px 20px;
    font-size: 12px;
    text-transform: uppercase;
  }

  &.inProgress {
    content: "Wydana do doręczenia";
    color: #ffab2b;
    background: rgb(255, 171, 43, 0.08);
  }
  &.received {
    content: "Odebrana";
    color: #13c296;
    background: rgba(19, 194, 150, 0.08);
  }
  &.requires,
  &.toPickedUp {
    content: "Wymaga działania";
    color: #d80027;
    background: rgba(216, 0, 39, 0.08);
    position: relative;
  }
  &.toPickedUp::after {
    content: url(../img-svg/warning1.svg);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -30px;
  }
  &.canceled {
    content: "Anulowana";
    color: #000;
    background: rgba(0, 0, 0, 0.08);
    text-transform: uppercase;
  }

  &.active {
    color: #7974ff;
    border: 1px solid #7974ff;
  }

  &:last-of-type {
    margin-right: 0;
  }
}
