@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap");

@import "./scss/variables.scss";
@import "./scss/typography.scss";
@import "./scss/reset";
@import "./scss/components.scss";
@import "./scss/LoginHeader.scss";
@import "./scss/buttons.scss";
@import "./scss/base.scss";
@import "./scss/login-base.scss";
@import "./scss/Landing.scss";
@import "./scss/WhyUs.scss";
@import "./scss/Card.scss";
@import "./scss/Dashboard.scss";
@import "./scss/Chip.scss";
@import "./scss/Login.scss";
@import "./scss/Header.scss";
@import "./scss/BottomSignUp.scss";
@import "./scss/PasswordRemind.scss";

#login,
#register__wrapper,
#registerCorrect__wrapper {
  margin: 47px $sideMargin 0;
  @include breakpoint(mobile) {
    margin: 120px $mobileMargin 0;
  }
}

.hero__wrapper,
.knowledge__wrapper,
#contact,
#carousel,
#shipment-main__wrapper,
#shipmentFinalization {
  margin: 0 $sideMargin 0;
  @include breakpoint(mobile) {
    margin: 120px $mobileMargin 0;
  }
}

.landingBackground {
  @include breakpoint(mobile) {
    border-radius: 32px;
    background: linear-gradient(
      180deg,
      rgba(238, 238, 238, 0) 0%,
      #eeeeee 100%
    );
    z-index: -1;
  }
}

.reviews__wrapper,
.bottomSignUp__wrapper,
#whyUs {
  margin: 0 $sideMargin 0;
  @include breakpoint(mobile) {
    margin: 0;
  }
}

#whyUs {
  margin-top: 150px;
  @include breakpoint(mobile) {
    margin-top: 33px;
  }
}

#carousel {
  margin-top: 180px;
}

.preLoginHeader,
.loginHeader {
  padding: 47px $sideMargin;
  @include breakpoint(mobile) {
    margin: 0 0 20px 0;
    padding: 0 $mobileMargin;
  }
}

#footer {
  .preLoginHeader,
  .loginHeader {
    background-color: transparent;
    margin: 0;
    padding: 47px 0;
  }
  .preLoginHeader,
  .loginHeader {
    margin: 100px 0 0;
    @include breakpoint(mobile) {
      margin: 100px 0;
    }
  }
  .loginHeader {
    @include breakpoint(mobile) {
      margin: 100px 0 0;
    }
  }
  a {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #d8d8d8;
    &:hover {
      color: $btnColorPrimary;
    }
  }
  .menuItem.active {
    a {
      color: $btnColorPrimary;
    }
  }
}

#bottomSignUp {
  margin-top: 60px;
  margin-bottom: 70px;
  @include breakpoint(mobile) {
    margin-bottom: 24px;
  }
}

.toast {
  top: 100px;
  right: 100px;
  width: 500px;
  height: 100px;
}

table {
  width: 100%;
  border-collapse: collapse;
  tbody tr {
    border-bottom: 1px solid #e8ecef;
    td {
      padding: 1rem 0;
      img {
        margin-right: 10px;
      }
      img.warningIcon {
        margin-right: 0;
        margin-left: 10px;
      }
      span {
        color: #212b36;
        font-family: Rubik;
        font-size: 14px;
        line-height: 21px;
      }
      div.flex-center {
        display: flex;
        align-items: center;
      }
    }
  }
  thead {
    tr {
      border-bottom: 1px solid #e8ecef;
      th {
        padding: 1rem 0;
      }
    }
    display: table-header-group;
    .col img {
      margin-right: 10px;
    }
    .col span {
      color: #252631;
      font-family: Rubik;
      font-size: 14px;
      line-height: 19px;
      opacity: 0.7;
      text-transform: uppercase;
      font-weight: normal;
    }
  }
  tbody {
    display: table-row-group;
    tr:hover {
      cursor: pointer;
      background: rgba(121, 116, 255, 0.03);
    }
    .col {
      display: table-cell;
      min-width: 25px;
      max-width: 200px;
    }
  }
}
