@import "./variables.scss";

#multi-parcel {
  margin: 50px 235px 0px;
  @include breakpoint(mobile) {
    margin: 50px $mobileMargin 0px;
  }

  .dragNdrop__container {
    height: 50vh;
    min-height: 390px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;
    position: relative;
    .formItemError {
      position: absolute;
      top: -25px;
      left: 50%;
      transform: translateX(-50%);
    }
    .drop-area {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(
        180deg,
        #f0f0f0 0%,
        rgba(255, 255, 255, 0) 100%
      );
      border: 1px dashed #212b36;
      box-sizing: border-box;
      border-radius: 16px;
      cursor: grabbing;
      position: relative;
      .drop-area__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .drop-area__content > * {
        margin: 20px;
      }
      p {
        font-size: 24px;
        line-height: 21px;
        color: #212b36;
      }
    }
    .informationHidden {
      width: (100%);
      height: 100%;
      display: flex;
      justify-content: center;
      border-radius: 10px;
      outline: 2px solid #fff2ee;
      opacity: 0;
      transition: all 0.3s ease;
      cursor: default;
      position: absolute;
      z-index: -1;
      padding: 43px 50px 36px;
      overflow: scroll;
      @include breakpoint(mobile) {
        padding: 20px 10px;
        height: fit-content;
      }
      &.informationActive {
        background-color: #fff2ee;
        opacity: 1;
        z-index: 1000;
      }
      .plusButton {
        position: relative;
        cursor: pointer;
        transition: all 0.5s ease-in-out;
        z-index: 10000;
        transform: translateY(8px);
        &__icon {
          top: 100px;
          right: 20px;
          left: unset;
          width: 27px;
          height: 3px;
          background-color: #212b36;
          border-radius: 5px;
          transition: all 0.5s ease-in-out;
          transform: rotate(45deg);
        }
        &__icon::before {
          content: "";
          position: absolute;
          width: 27px;
          height: 3px;
          border-radius: 5px;
          transform: rotate(90deg);
          background-color: #212b36;
          border-radius: 5px;
          transition: all 0.5s ease-in-out;
        }
      }
      .info {
        width: 100%;
        top: 0;
        left: 0;
        transform: unset;
        display: flex;
        flex-direction: column;
        font-style: normal;
        padding-right: 10px;
        line-height: 21px;
        color: #212b36;
        .info__title {
          display: flex;
          justify-content: space-between;
          font-weight: bold;
          margin-bottom: 45px;
          p {
            font-size: 18px;
          }
          span {
            font-weight: normal;
            cursor: pointer;
          }
        }
        .info__content {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          span {
            font-size: 14px;
            a {
              font-size: inherit;
            }
          }
        }
      }
    }

    input[type="file"] {
      display: none;
    }
    .file-upload {
      background-color: $btnColorPrimary;
      color: #fff;
      border-radius: 32px;
      padding: 15px 32px;
      cursor: pointer;
      transition: all 0.5s ease;
      & > span {
        white-space: nowrap;
      }
      &:hover {
        background-color: $btnColorPrimaryHover;
      }
    }
  }
  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    .questionMark {
      display: flex;
      align-items: center;
      padding: 0 0 20px;
      cursor: pointer;
      .qestionIcon {
        fill: $orange;
      }
      & > * + * {
        margin: 0 0 0 10px;
        color: $orange;
        text-decoration: underline;
      }
    }
    @include breakpoint(mobile) {
      flex-direction: column;
      & > * + * {
        margin: 20px 0 0 0;
      }
    }
  }
}
