@import "./variables.scss";

.loginHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #eeeeee;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
  @include breakpoint(mobile) {
    padding: 0px $mobileMargin;
    background-color: #eeeeee;
    position: fixed;
    top: 0;
    width: 100%;
    height: 80px;
    z-index: 110;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    transition: all 0.5s ease;

    &.active {
      background-color: #eeeeee;
    }
  }
  .menu-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin: 0 16px;
    & > * + * {
      margin: 0 0 0 50px;
    }
    .option.user-email {
      display: none;
    }

    @include breakpoint(mobile) {
      padding-left: $mobileMargin;
      display: hide;
      justify-content: flex-start;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 75px;
      left: -100%;
      opacity: 0;
      transition: all 0.5s ease;
      flex-direction: column;
      list-style-type: none;
      & > * + * {
        margin: 10px 0 0 0px;
      }
      background-color: #eeeeee;
      .option.user-email {
        display: block;
        align-self: center;
        a {
          color: $btnColorPrimary;
        }
      }
      &.active {
        background-color: #eeeeee;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 10;
        align-content: flex-start;
        justify-content: flex-start;
        // padding-top: 20px;
        min-height: 95vh;
        height: fit-content;
        .btn {
          width: 100%;
          border: none;
          &.logout {
            margin-top: 20px;
            color: $btnColorPrimary;
          }
        }
      }
      .option {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 20px 0px 0px;
        width: 100%;
        &.balance {
          display: none;
          @include breakpoint(mobile) {
            display: unset;
            margin-bottom: 20px;
          }
        }
        a {
          width: 100%;
          text-align: left;
        }
      }
    }
  }
  .nav-side {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > * + * {
      margin: 0 0 0 30px;
    }
    .account {
      display: flex;
    }
    a {
      color: #212b36;
    }
    a:focus,
    a:hover {
      text-decoration: none;
      color: $btnColorPrimary;
    }
    li:first-child:after {
      padding-left: 10px;
      content: url(../img-svg/VectorRight.svg);
    }
    @include breakpoint(mobile) {
      display: none;
    }
  }
  .nav-side.mobile-option {
    display: none;
  }
  .buttons {
    @include breakpoint(mobile) {
      display: none;
    }
  }
  .buttons.mobile-option {
    display: none;
    @include breakpoint(mobile) {
      align-self: center;
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      & > * + * {
        margin: 10px 0 0 0;
      }
    }
  }

  .burger-menu {
    display: none;
    @include breakpoint(mobile) {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 30px;
      height: 30px;
      cursor: pointer;
      transition: all 0.5s ease-in-out;
      &__icon {
        width: 27px;
        height: 4px;
        background-color: $btnColorPrimary;
        border-radius: 5px;
        transition: all 0.5s ease-in-out;
      }
      &__icon::before,
      &__icon::after {
        content: "";
        position: absolute;
        width: 27px;
        height: 4px;
        background-color: $btnColorPrimary;
        border-radius: 5px;
        transition: all 0.5s ease-in-out;
      }
      &__icon::before {
        transform: translateY(-10px);
      }
      &__icon::after {
        transform: translateY(10px);
      }
      &.open .burger-menu__icon {
        transform: translateX(30px);
        background-color: transparent;
      }
      &.open .burger-menu__icon::before {
        transform: translate(-30px, 0px) rotate(45deg);
      }
      &.open .burger-menu__icon::after {
        transform: translate(-30px, 0px) rotate(-45deg);
      }
    }
  }
  li {
    color: #212b36;
    white-space: nowrap;
    &.menuItem.active {
      a {
        color: $btnColorPrimary;
      }
    }
  }
}
#footer {
  .loginHeader {
    @include breakpoint(mobile) {
      background-color: #212b36;
      position: static;
      display: flex;
      justify-content: center;
      .burger-menu {
        display: none;
      }
      section {
        margin-top: 30px;
      }
    }
    .account {
      display: none;
    }
    .menuItem.active {
      a {
        color: $btnColorPrimary;
      }
    }
  }
}

.loginHeader__lowerNav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 139px 15px 135px;
  .searchSection {
    display: flex;
    input {
      background: #fcfdfe;
      border: 1px solid #e9edf4;
      border-radius: 6px;
      height: 48px;
      width: 383px;
      padding-left: 20px;
    }
  }
  .lowerNav__btns {
    .addToFollow,
    .addPackage {
      border-radius: 32px;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.5px;
      text-align: left;
      padding: 12px 0px 12px 21px;
      vertical-align: middle;
    }
    .addToFollow::after {
      content: url(../img-svg/PlusSymbolDark.svg);
      padding-left: 6px;
      padding-right: 16px;
    }
    .addPackage::after {
      content: url(../img-svg/PlusSymbol.svg);
      padding-left: 6px;
      padding-right: 16px;
    }
    .addToFollow {
      background: #ffffff;
      border-radius: 32px;
    }
    .addPackage {
      color: #ffffff;
      background-color: #7974ff;
      margin-left: 16px;
    }
  }
}

.balance {
  font-weight: bold;
}
.balance.negativeBalance {
  color: #d80027 !important;
}

.loginHeader__topNav .nav-side a.negativeBalance {
  color: #d80027;
}
