@import "./variables.scss";

.recommended-article {
  display: flex;
  margin-bottom: 30px;
  .article-picture {
    margin-right: 30px;
    width: 100%;
    max-width: fit-content;
    @include breakpoint(mobile) {
      width: 50%;
    }
    img {
      max-width: 150px;
      max-height: 150px;
      @include breakpoint(mobile) {
        width: 100%;
      }
    }
  }
  .article-data {
    display: flex;
    flex-direction: column;
    .article-time {
      @include basicP;
      color: #212b36;
      opacity: 0.6;
      margin-bottom: 14px;
    }
    .article-time::before {
      content: url(../img-svg/clock.svg);
      padding-right: 12px;
      position: relative;
      top: 2px;
    }
    a {
      h1 {
        @include smallHeader;
        margin-bottom: 26px;
      }
      h1:hover {
        text-decoration: underline;
      }
    }
    .article-author {
      @include basicP;
      color: #212b36;
      opacity: 0.6;
    }
  }
}
