@import "../scss/variables.scss";
@import "../scss/typography.scss";
@import "./WhyUsCardFooter.scss";

.orangeAdvantage {
  background: #ff9371;
  @include shadow-radius;
  @include cardCentering;
  h3 {
    align-self: flex-start;
    margin: $headerMargin;
    @include WhyUsCardHeaderTypo;
  }
  p {
    margin: $textMargin;
    @include WhyUsCardTextTypo;
  }
}
.greenAdvantage {
  background: #56cbb9;
  @include shadow-radius;
  @include cardCentering;
  h3 {
    align-self: flex-start;
    margin: $headerMargin;
    @include WhyUsCardHeaderTypo;
  }
  p {
    margin: $textMargin;
    @include WhyUsCardTextTypo;
  }
  @include cardCentering;
}
.blueAdvantage {
  background: #863bff;
  @include shadow-radius;
  @include cardCentering;
  h3 {
    align-self: flex-start;
    margin: $headerMargin;
    @include WhyUsCardHeaderTypo;
  }
  p {
    margin: $textMargin;
    @include WhyUsCardTextTypo;
  }
}
