#balance-history {
  table {
    width: 100%;
  }
  tr {
    text-align: left;
  }
  th {
    font-family: Rubik;
    font-size: 14px;
    line-height: 19px;
    color: #252631;
    font-weight: normal;
    opacity: 0.7;
    padding-bottom: 12px;
    border-bottom: 1px solid #e8ecef;
  }
  td {
    width: fit-content;
    max-width: 175px;
    border-bottom: 1px solid #e8ecef;
    padding: 12px 0px 12px 0;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 21px;
    color: #212b36;
  }

  td:nth-child(3) {
    width: 40%;
  }
  td:last-child {
    text-align: right;
  }

  .invoiceData {
    position: relative;
    padding-left: 20px;
  }
  .invoiceData::before {
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
  }
  .invoiceData::before {
    content: url(../img-svg/callendar.svg);
  }
}
