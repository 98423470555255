@import "../scss/variables.scss";

.bottomSignUp__wrapper {
  background-color: $btnColorPrimary;
  @include shadow-radius;
  height: 350px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include breakpoint(mobile) {
    justify-content: flex-end;
  }
  div {
    width: 40%;
    margin-left: 10%;
    @include breakpoint(mobile) {
      width: 100%;
      margin-left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    h2 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 30px;
      color: $textColorPrimary;
      margin-bottom: 40px;
      @include breakpoint(mobile) {
        margin-bottom: 20px;
        width: 90vw;
        text-align: left;
      }
    }
    .btn {
      color: $btnColorPrimary;
      border: 1px solid transparent;
      @include breakpoint(mobile) {
        margin-bottom: 20px;
        align-self: flex-start;
        margin-left: 5vw;
      }
    }
    .btn:hover {
      @include btnHover;
      background-color: #fff;
    }
    .btn:focus {
      @include btnFocus;
    }
  }
  img {
    z-index: 1;
    position: absolute;
    right: 0px;
    top: -25px;
    @include breakpoint(mobile) {
      right: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 80%;
      max-width: 400px;
    }
  }
}
