@import "./variables.scss";

.formItemError {
  color: $failure;
  padding-bottom: 10px;
}

.formItemError:hover {
  cursor: pointer;
  text-decoration: underline;
}

#loginForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16vh;

  @include breakpoint(mobile) {
    margin: 0;
    align-items: flex-start;
  }

  h1 {
    @include breakpoint(mobile) {
      width: 70vw;
      text-align: left;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    margin-top: 50px;

    @include breakpoint(mobile) {
      margin-top: 25px;
      width: 100%;
    }

    .btn {
      margin-top: 60px;
    }

    .formItem {
      width: 25.6rem;

      @include breakpoint(mobile) {
        margin-bottom: 25px;
        width: 100%;
      }
    }

    .formItemAlert {
      display: flex;
      justify-content: center;
    }
  }

  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #212b36;

    @include breakpoint(mobile) {
      align-self: center;
    }

    a {
      font-size: 14px;
      line-height: 22px;
      cursor: pointer;
    }
  }

  button {
    margin: 0;
    width: 190px;
  }
}

#loginBackground {
  @include overflowSolution;
  @include shapePositions1;

  .rect {
    @include bottomStripe;
  }

  @include breakpoint(mobile) {
    display: none;
  }
}
