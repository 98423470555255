@import "./variables.scss";

.single-article {
  max-height: 360px;
  display: flex;
  margin: 60px 0 30px;
  width: 100%;
  & > * + * {
    margin: 0 0 0 30px;
  }
  @include breakpoint(mobile) {
    flex-direction: column;
    & > * + * {
      margin: 30px 0 0 0;
    }
  }
  .article-picture {
    img {
      border-radius: 8px;
      width: auto;
      max-height: 360px;
    }
    @include breakpoint(mobile) {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
  .article-data {
    display: flex;
    flex-direction: column;
    .article-time {
      @include basicP;
      color: #212b36;
      opacity: 0.6;
      margin: 14px 0;
    }
    .article-time::before {
      content: url(../img-svg/clock.svg);
      padding-right: 12px;
      position: relative;
      top: 2px;
    }
    a {
      margin-bottom: 13px;
      color: #212b36;
      h1 {
        @include smallHeader;
        margin-bottom: 30px;
      }
      h1:hover {
        text-decoration: underline;
      }
    }
    .article-author {
      @include basicP;
      color: #212b36;
      opacity: 0.6;
      margin-bottom: 20px;
    }
  }
}
.single-article:nth-child(even) {
  flex-direction: row-reverse;
  & > * + * {
    margin: 0 30px 0 0px;
  }
  @include breakpoint(mobile) {
    flex-direction: column;
    & > * + * {
      margin: 0 0px 0 0px;
    }
  }
}
