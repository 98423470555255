@import "./LoginHeader.scss";
@import "./variables.scss";
@import "./buttons.scss";

.menu {
  list-style: none;
}

.menuItem {
  font-weight: 400;
  display: inline;
  a {
    color: #212b36;
  }
  a.active:hover {
    font-weight: 700;
    color: $btnColorPrimary;
    transition: 0.4s;
  }
  a:focus {
    color: $btnColorPrimary;
    text-decoration: none;
  }
  &.active {
    a {
      color: $btnColorPrimary;
    }
  }
}

form input.small {
  width: 60px;
}
form input.wide {
  width: 370px;
}

.alert.success {
  background: rgba(19, 194, 150, 0.2);
  border-left: 3px solid #56cbb9;
  padding: 8px 12px 8px 12px;
}

.checkboxLabel {
  input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }
}
