@import "../scss/variables.scss";

#passwordReminder__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16vh;

  @include breakpoint(mobile) {
    margin: 120px $mobileMargin;
    align-items: flex-start;
  }
}

#passwordReminder {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @include breakpoint(mobile) {
    width: 100%;
  }

  h1 {
    margin-bottom: 3.938rem;

    @include breakpoint(mobile) {
      margin-bottom: 1.688rem;
      align-self: flex-start;
    }
  }

  form {
    flex-direction: column;
    margin-top: 0;

    .formItem {
      width: 25.6rem;

      @include breakpoint(mobile) {
        width: 100%;
      }

      button {
        margin: 70px 0 0;
        width: 190px;

        @include breakpoint(mobile) {
          margin-top: 2.75rem;
        }
      }
    }

    .formItemAlert {
      display: flex;
      justify-content: center;
      padding: 0;
      margin: 0;
    }

    @include breakpoint(mobile) {
      // margin-top: 25px;
      width: 100%;
    }
  }
}

#passwordReminderBackground {
  @include overflowSolution;
  @include shapePositions2;

  .rect {
    @include bottomStripe;
  }
}
