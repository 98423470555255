@import "./variables.scss";

.mobileModal {
  position: relative;
  display: none;
  transition: all 0.5s ease;
  @include breakpoint(mobile) {
    display: block;
  }
  .modal {
    display: none;
    @include breakpoint(mobile) {
      display: none;
      flex-direction: column;
      justify-content: center;
      padding: 20px 20px 27px;
      width: 225px;
      position: absolute;
      top: -15px;
      left: 200px;
      transition: all 0.5s ease;
      background-color: $btnColorPrimary;
      border-radius: 24px;
      z-index: 98;
      opacity: 0;
      .closeModal {
        align-self: flex-end;
        margin-bottom: 32px;
        span {
          letter-spacing: 0.5px;
          color: #ffffff;
          margin-right: 20px;
        }
      }
      .modalButtons {
        white-space: nowrap;
        display: flex;
        flex-direction: column;
        justify-content: center;
        & > * + * {
          margin: 9px 0 0 0;
        }
        .btn {
          padding-left: 10px;
          position: relative;
          &::after {
            content: url(../img-svg/PlusSymbolDark.svg);
            position: absolute;
            top: 13px;
            right: 15px;
          }
        }
      }
      &.active {
        display: flex;
        left: -190px;
        top: -15px;
        opacity: 1;
        transition: all 0.5s ease;
      }
    }
  }
  .plusButton {
    display: none;
    @include breakpoint(mobile) {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 30px;
      height: 30px;
      cursor: pointer;
      transition: all 0.5s ease-in-out;
      z-index: 99;
      &__icon {
        width: 27px;
        height: 4px;
        background-color: $btnColorPrimary;
        border-radius: 5px;
        transition: all 0.5s ease-in-out;
      }
      &__icon::before {
        content: "";
        position: absolute;
        width: 27px;
        height: 4px;
        transform: rotate(90deg);
        background-color: $btnColorPrimary;
        border-radius: 5px;
        transition: all 0.5s ease-in-out;
      }
      &.open .plusButton__icon {
        transform: rotate(45deg);
      }
      &.open .plusButton__icon,
      &.open .plusButton__icon::before {
        background-color: #fff;
      }
    }
  }
}
