@import "./variables.scss";
.textAreaWrapper {
  width: 100%;
  position: relative;
  height: 204px;
  .textAreaBox {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 45px;
    display: inline-block;
    textarea {
      background-color: #fff;
      border-radius: 6px;
      border: 1px solid #e9edf4;
      color: #212b36;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      left: 0;
      line-height: 22px;
      padding: 13px 16px;
      position: absolute;
      resize: none;
      top: 0;
      transition: 0.3s ease-in-out;
      width: 100%;
      @include breakpoint(mobile) {
        max-width: 100%;
      }
      &.invalid {
        border-bottom: 1px solid red !important;
      }
    }
    span {
      position: absolute;
      top: 1px;
      left: 1px;
      padding: 13px 16px;
      display: inline-block;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #212b36;
      opacity: 0.5;
      transition: 0.5s;
      pointer-events: none;
    }

    textarea:hover {
      background-color: #f9f9ff;
    }
    textarea:focus,
    textarea:not(:placeholder-shown) {
      padding: 20px 16px 6px;
    }
    textarea:focus {
      border-bottom: 1px solid #7974ff;
      background-color: #f9f9ff;
    }
    textarea:focus ~ span,
    textarea:not(:placeholder-shown) ~ span {
      transform: translate(0px, -10px);
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 22px;
      color: #212b36;
      opacity: 0.5;
    }
  }
  small.errorMsg {
    position: absolute;
    left: 0;
    bottom: -22px;
  }
}
