@import "./variables.scss";

.main-article {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  .article-picture {
    margin-bottom: 30px;
    // max-width: 670px;
    // max-height: 366px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      @include breakpoint(mobile) {
        width: 100%;
      }
    }
    a > img {
      width: 100%;
      height: auto;
    }
  }
  .article-data {
    .article-time {
      @include basicP;
      color: #212b36;
      opacity: 0.6;
      margin-bottom: 14px;
    }
    .article-time::before {
      content: url(../img-svg/clock.svg);
      padding-right: 12px;
      position: relative;
      top: 2px;
    }
    a > h1 {
      // @include bigHeader;
      margin-bottom: 26px;
    }
    a > h1:hover {
      text-decoration: underline;
    }
    .article-content {
      @include basicP;
      margin-bottom: 14px;
    }
    .article-author {
      @include basicP;
      color: #212b36;
      opacity: 0.6;
    }
  }
}
