@import "../scss/variables.scss";

#passwordReminderCorrect__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16vh;
}

#passwordReminderCorrect {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    margin-bottom: 3.938rem;
  }

  p {
    max-width: 75vw;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #212b36;

    a {
      color: #7974ff;
    }
  }

  button {
    margin-top: 93px;
    padding: 12px 0;
    width: 190px;
  }
}

#passwordReminderCorrectBackground {
  @include overflowSolution;
  @include shapePositions2;

  .rect {
    @include bottomStripe;
  }
}
