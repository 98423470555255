@import "./variables.scss";

#to-send {
  .flex {
    display: flex;
    &.column {
      flex-direction: column;
    }
  }
  .table-container {
    & * > img {
      padding-right: 10px;
      position: relative;
      top: 3px;
    }
    & * > span {
      color: #212b36;
      font-family: Rubik;
      font-size: 14px;
      line-height: 21px;
    }
    .header-container {
      align-items: center;
      border-bottom: 1px solid #e8ecef;
      display: grid;
      gap: 20px;
      grid-template-columns: repeat(24, 1fr);
      padding: 14px 0;

      @include breakpoint(mobile) {
        grid-template-columns: repeat(8, 1fr);

        .mobile-label {
          align-items: center;
          display: grid;
          gap: 20px;
          grid-column: 1 / 9;
          grid-template-columns: repeat(8, 1fr);

          .container {
            grid-column: 1 / 2;
          }
          span {
            grid-column: 2 / 9;
          }
        }
        .col-2,
        .col-4,
        .col-5,
        .col-6,
        .col-7,
        .col-8 {
          display: none;
        }
      }
      & * > span {
        color: #252631;
        font-family: Rubik;
        font-size: 14px;
        line-height: 19px;
        opacity: 0.7;
        text-transform: uppercase;
      }
      & > .mobile-label > span {
        display: none;

        @include breakpoint(mobile) {
          display: unset;
        }
      }
    }
    .row-container {
      align-items: flex-start;
      border-bottom: 1px solid #e8ecef;
      display: grid;
      gap: 20px;
      grid-template-columns: repeat(24, 1fr);
      padding: 24px 0;
      text-decoration: none;
      &:hover {
        background-color: rgba(121, 116, 255, 0.04);
      }
      & > * {
        align-items: flex-start;
        display: flex;
        text-decoration: none;
      }
      @include breakpoint(mobile) {
        grid-template-columns: repeat(8, 1fr);
      }
    }
    .col-1 {
      grid-column: 1 / 2;
      @include breakpoint(mobile) {
        grid-column: 1/ 2;
        // order: 1;
      }
    }
    .col-2 {
      grid-column: 2 / 4;
      @include breakpoint(mobile) {
        grid-column: 1/ 2;
        // order: 1;
      }
    }
    .col-3 {
      grid-column: 4 / 7;
      @include breakpoint(mobile) {
        grid-column: 1/ 2;
        // order: 1;
      }
    }
    .col-4 {
      grid-column: 7 / 10;
      @include breakpoint(mobile) {
        grid-column: 1/ 2;
        // order: 1;
      }
    }
    .col-5 {
      grid-column: 10 / 14;
      @include breakpoint(mobile) {
        grid-column: 1/ 2;
        // order: 1;
      }
    }
    .col-6 {
      grid-column: 14 / 16;
      @include breakpoint(mobile) {
        grid-column: 1/ 2;
        // order: 1;
      }
      img {
        top: -2px;
      }
    }
    .col-7 {
      grid-column: 16 / 19;
      @include breakpoint(mobile) {
        grid-column: 1/ 2;
        // order: 1;
      }
    }
    .col-8 {
      grid-column: 19 / 21;
      @include breakpoint(mobile) {
        grid-column: 1/ 2;
        // order: 1;
      }
    }
    .col-9 {
      grid-column: 21 / 23;
      @include breakpoint(mobile) {
        grid-column: 1/ 2;
        // order: 1;
      }
    }
    .col-10 {
      grid-column: 23 / 25;
      text-align: right;
      display: flex;
      justify-content: flex-end;
      span {
        color: $btnColorPrimary;
      }
      @include breakpoint(mobile) {
        grid-column: 1/ 2;
        // order: 1;
      }
    }

    .hidden {
      display: none;
    }
  }
}
