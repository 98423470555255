.whyUsCardFooterAdvantage {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 65px 0 0 20px;
  @include breakpoint(mobile) {
    margin: 20px 0 0 10px;
  }
  h1 {
    @include breakpoint(mobile) {
      font-size: 45px;
      line-height: 53px;
    }
  }
  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #212b36;
    opacity: 0.6;
    margin-bottom: 150px;
    @include breakpoint(mobile) {
      margin: 0;
    }
  }
}
